import { DeviceBatteryState } from '@shure/shared/angular/data-access/system-api/models';

import { BatteryState } from '../graphql/generated/cloud-sys-api';

export function mapBatteryStateFromSysApi(batteryState: BatteryState | null | undefined): DeviceBatteryState {
	switch (batteryState) {
		case BatteryState.Calculating:
			return DeviceBatteryState.Calculating;
		case BatteryState.Charging:
			return DeviceBatteryState.Charging;
		case BatteryState.Discharging:
			return DeviceBatteryState.Discharging;
		case BatteryState.Empty:
			return DeviceBatteryState.Empty;
		case BatteryState.Full:
			return DeviceBatteryState.Full;
		case BatteryState.OptimalStorage:
			return DeviceBatteryState.OptimalStorage;
		default:
			return DeviceBatteryState.Calculating;
	}
}
