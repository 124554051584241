import { DeviceMicState } from '@shure/shared/angular/data-access/system-api/models';

import { MicStatus } from '../graphql/generated/cloud-sys-api';

export function mapMicStatusFromSysApi(micStatus: MicStatus | null | undefined): DeviceMicState {
	switch (micStatus) {
		case MicStatus.Active:
			return DeviceMicState.Active;
		case MicStatus.Mute:
			return DeviceMicState.Mute;
		case MicStatus.Off:
			return DeviceMicState.Off;
		case MicStatus.OnCharger:
			return DeviceMicState.OnCharger;
		case MicStatus.OutOfRange:
			return DeviceMicState.OutOfRange;
		case MicStatus.Standby:
			return DeviceMicState.Standby;
		default:
			return DeviceMicState.Unknown;
	}
}
