/**
 * @ignore
 */
export function identity(x) {
  return x;
}
/**
 * @ignore
 */
export async function identityAsync(x) {
  return x;
}

