import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';

import result from '../generated/system-api.generated';

import { ApolloCacheFactory } from './apollo-cache-factory.service';
import { APOLLO_LINK_PROVIDERS, ApolloLinkProvider, sortLinkProvidersByPriority } from './apollo-link.provider';
import { MessagePortApolloConnectionService } from './message-port-apollo-connection.service';

export const SYS_API_MESSAGE_PORT = new InjectionToken<MessagePort>('SYS_API_MESSAGE_PORT');

@Injectable()
export class SysApiMessagePortConnectionService extends MessagePortApolloConnectionService {
	constructor(
		apolloCacheFactory: ApolloCacheFactory,
		@Inject(SYS_API_MESSAGE_PORT) messagePort: MessagePort,
		@Optional() @Inject(APOLLO_LINK_PROVIDERS) linkProviders: ApolloLinkProvider[]
	) {
		super(
			'sysApi',
			result.possibleTypes,
			apolloCacheFactory,
			messagePort,
			sortLinkProvidersByPriority(linkProviders ?? [])
		);
	}
}
