import { Duration } from 'ts-luxon';

export function mapDurationFromSysApi(duration: string | null | undefined): number {
	if (!duration) return 0;

	// Converts ISO 8601 time duration to ms
	return Duration.fromISO(duration).toMillis();
}

/**
 * Map uptime (ISO 8601 time duration) to [h]:mm:ss if it exists.
 */
export function mapUptimeDurationFromSysApi(uptime: string | undefined | null): string | undefined {
	if (!uptime) {
		return undefined;
	}

	return Duration.fromISO(uptime).toFormat('hh:mm:ss');
}
