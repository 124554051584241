import { Injectable } from '@angular/core';
import { ApolloCache, NormalizedCacheObject } from '@apollo/client';

@Injectable({
	providedIn: 'root'
})
export class ApolloCacheManager {
	private caches = new Map<string, ApolloCache<NormalizedCacheObject>>();

	public getApolloCache(name: string): ApolloCache<NormalizedCacheObject> | undefined {
		return this.caches.get(name);
	}

	public addCache(name: string, cache: ApolloCache<NormalizedCacheObject>): void {
		this.caches.set(name, cache);
	}
}
