import { Signal } from '@angular/core';
import { Observable } from 'rxjs';

import { DeviceDiscoveryEvent } from '../api-instance-sys-api/sys-api-device-discovery-api.service';

export abstract class DeviceDiscoveryApiService {
	/**
	 * Signals exposed by the service to communiate the state of device discovery.
	 */
	public abstract deviceDiscoveryInProgress: Signal<boolean>;
	public abstract numDiscoveredDevices: Signal<number>;

	/**
	 * Observable stream that emits a device ID whenever a device is added
	 */
	public abstract deviceAdded$(): Observable<string>;

	/**
	 * Observable stream that emits a device ID whenever a device is removed
	 */
	public abstract deviceRemoved$(): Observable<string>;

	/**
	 * Observable stream that emits device discovery events
	 */
	public abstract discoveryEvents$(): Observable<DeviceDiscoveryEvent>;

	/**
	 * Observable stream that emits a new value whenever the discovered device count changes
	 */
	public abstract discoveredDevicesCount$(): Observable<number>;

	/**
	 * This function is responsible for emitting a list of devices, and properites as determined by
	 * the supplied query function, that have been discovered. Essentially, this function creates and
	 * returns a new Observable the caller can subscribe that is a list of discovered Devices
	 * along with their properties. As devices are added/removed, a new list is emitted on the observable.
	 *
	 * - This discovery service maintains a list of discovered devices, and then
	 *   keeps that list accurate as deviceAdded and deviceRemoved events are received.
	 *
	 * - When the service starts/initializes, a query is performed for every device currently claimed
	 *   Each device returned results in a deviceAdded event being added to the discoveryEvent subject
	 *
	 * - As deviceAdded and removed events are received, the events are added to the discoveryEvent subject
	 *
	 * - The contents and changes to the discoveryEvent subject drive this function.
	 *
	 * Within the creation of the Observable that's returned (a closure), is the storage of the returnedDevices
	 * and their respective query handler.
	 *
	 * - When a deviceAdded event is received, the query is dispatched for the device. When the query
	 *   response is received, the device and it's data is added to the list of returned devices, then
	 *   the updated list of returned devices is emitted on the Observable. At this point, the device is avaiable
	 *   for anyone subscirbe to the over-all Observable. For book keeping purposes, the device to query handler
	 *   mapping is stored so we can unsubscribs from the query if a deviceRemoved event is received later on.
	 *
	 * - When a deviceRemoved event is received, we unsubscribe from the query, remove the device from the
	 *   list of returned devices, then emit the updated list of devices on the Observable.
	 *
	 * @param elementQueryFunction The query function to execute and subscribe to when a device is added.
	 * @param elementFilterFunction A filter function to omit exeucting query fuction on some devices.
	 * @returns
	 */
	public abstract getDiscoveredDevicesByQuery$<ElementType extends { id: string }>(
		elementQueryFunction: (id: string) => Observable<ElementType>,
		elementFilterFunction?: (element: ElementType) => boolean
	): Observable<ElementType[]>;
}
