import { Injectable } from '@angular/core';
import { MutationResult } from 'apollo-angular';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, map, take } from 'rxjs/operators';

import { DetectionToken } from '@shure/cloud/shared/models/devices';
import { ILogger } from '@shure/shared/angular/utils/logging';

import { DetectionTokenApiService } from '../api/detection-token-api.service';

import { GenerateDetectionTokenGQL, GenerateDetectionTokenOpResult } from './graphql/generated/cloud-sys-api';

@Injectable({
	providedIn: 'root'
})
export class SysApiDetectionTokenApiService implements DetectionTokenApiService {
	private readonly logger: ILogger;

	constructor(
		logger: ILogger,
		private readonly detectionTokenGQL: GenerateDetectionTokenGQL
	) {
		this.logger = logger.createScopedLogger('SysApiDetectionTokenApiService');
	}

	/**
	 * Initiates a call to the BE for a detection token
	 * @returns an observable to the resulting detection token string
	 */
	public detectionToken$(): Observable<DetectionToken> {
		this.logger.trace('detectionToken', 'Requesting detection token');
		return this.detectionTokenGQL.mutate().pipe(
			take(1),
			filter((result) => !!result),
			map((result: MutationResult<GenerateDetectionTokenOpResult>) => {
				this.logger.trace('detectionToken', 'Token created', result);
				return <DetectionToken>result.data?.generateDetectionToken;
			}),
			catchError((error: Error) => {
				this.logger.error('detectionTokenMutation', 'Failed to generate a detection token', {
					error
				});
				return throwError(() => error);
			})
		);
	}
}
