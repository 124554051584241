"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MessagePortLink = exports.GraphQlResponseMessage = exports.SerializedGraphQlError = exports.GraphQlRequestMessage = void 0;
const import_apollo_client_1 = require("./import-apollo-client");
const zen_observable_ts_1 = require("zen-observable-ts");
const graphql_1 = require("graphql");
const z = __importStar(require("zod"));
const assert_never_1 = require("assert-never");
exports.GraphQlRequestMessage = z.object({
  type: z.literal('execute'),
  id: z.number(),
  query: z.string(),
  variables: z.record(z.unknown()),
  operationName: z.string().optional()
}).or(z.object({
  type: z.literal('cancel'),
  id: z.number()
}));
exports.SerializedGraphQlError = z.object({
  name: z.string(),
  message: z.string(),
  stack: z.string().optional(),
  locations: z.array(z.object({
    line: z.number(),
    column: z.number()
  })).optional(),
  path: z.array(z.string().or(z.number())).optional(),
  positions: z.array(z.number()).optional(),
  extensions: z.record(z.string(), z.unknown()).optional()
});
exports.GraphQlResponseMessage = z.object({
  type: z.literal('result'),
  id: z.number(),
  data: z.record(z.unknown()).nullable(),
  errors: z.array(exports.SerializedGraphQlError).optional()
}).or(z.object({
  type: z.literal('complete'),
  id: z.number()
}));
class MessagePortLink extends import_apollo_client_1.ApolloLink {
  port;
  constructor(port) {
    super();
    this.port = port;
    this.port.addEventListener('message', message => {
      const parsed = exports.GraphQlResponseMessage.safeParse(message.data);
      if (!parsed.success) {
        // eslint-disable-next-line no-console -- @shure/api-logger does not yet work in web context
        console.error('Failed to parse response: ', parsed.error);
        return;
      }
      const observer = this.observers.get(parsed.data.id);
      if (observer === undefined) {
        return;
      }
      switch (parsed.data.type) {
        case 'result':
          {
            const errors = parsed.data.errors?.map(serialized => new graphql_1.GraphQLError(serialized.message, undefined, undefined, serialized.positions, serialized.path, undefined, serialized.extensions));
            observer.next?.({
              data: parsed.data.data,
              errors
            });
          }
          break;
        case 'complete':
          this.observers.delete(parsed.data.id);
          observer.complete?.();
          break;
        default:
          (0, assert_never_1.assertNever)(parsed.data);
      }
    });
  }
  observers = new Map();
  observerId = 0;
  request(operation) {
    return new zen_observable_ts_1.Observable(observer => {
      const id = ++this.observerId;
      this.observers.set(id, observer);
      this.port.postMessage({
        type: 'execute',
        id,
        query: (0, graphql_1.print)(operation.query),
        variables: operation.variables,
        operationName: operation.operationName
      });
      return () => {
        if (this.observers.delete(id)) {
          this.port.postMessage({
            type: 'cancel',
            id
          });
        }
      };
    });
  }
}
exports.MessagePortLink = MessagePortLink;
