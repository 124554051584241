import { WritableSignal } from '@angular/core';
import { Observable } from 'rxjs';

import { MutationResult, UpdateRequestResponse, UpdateResponse } from '@shure/cloud/shared/models/http';

import {
	UpdateDeviceFirmwareInput,
	AssociateTagMutationOpResult,
	DissociateTagMutationOpResult
} from '../api-instance-sys-api/graphql/generated/cloud-sys-api';

export type AssociateTagMutationResult = MutationResult<AssociateTagMutationOpResult['associateTag']>;
export type DissociateTagMutationResult = MutationResult<DissociateTagMutationOpResult['dissociateTag']>;

export abstract class CloudDeviceApiService {
	public abstract readonly lastFWUpdateTime: WritableSignal<number>;

	public abstract setIdentify(deviceId: string, identify: boolean): Observable<UpdateRequestResponse<string>>;
	public abstract setMute(deviceId: string, deviceMute: boolean): Observable<UpdateRequestResponse<string>>;
	public abstract setDeviceName(deviceId: string, name: string): Observable<UpdateResponse<void, string>>;
	public abstract rebootDevice$(deviceId: string): Observable<UpdateResponse<void, string>>;
	public abstract updateFirmware$(updates: UpdateDeviceFirmwareInput[]): Observable<UpdateResponse<void, string>>;
	public abstract associateTag(deviceIds: string[], tag: string): Observable<AssociateTagMutationResult>;
	public abstract dissociateTag(deviceIds: string[], tag: string): Observable<DissociateTagMutationResult>;
}
