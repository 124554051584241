import { InjectionToken } from '@angular/core';
import { ApolloLink } from '@apollo/client';

export const APOLLO_LINK_PROVIDERS = new InjectionToken<ApolloLinkProvider[]>('ApolloLinkProvider');

export interface ApolloLinkWithPriority {
	link: ApolloLink;
	priority: number;
}
export function sortLinkProvidersByPriority(linkProviders: ApolloLinkProvider[]): ApolloLink[] {
	return linkProviders
		.map((e) => e.createApolloLink())
		.sort((a, b) => a.priority - b.priority)
		.map((e) => e.link);
}

export interface ApolloLinkProvider {
	/**
	 * Returns a Apollo Link and a priority order for inserting in the link chain.
	 * @returns { link: ApolloLink; priority: number }
	 */
	createApolloLink(): ApolloLinkWithPriority;
}
