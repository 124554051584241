"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getParsedType = exports.ZodParsedType = exports.objectUtil = exports.util = void 0;
var util;
(function (util) {
  util.assertEqual = val => val;
  function assertIs(_arg) {}
  util.assertIs = assertIs;
  function assertNever(_x) {
    throw new Error();
  }
  util.assertNever = assertNever;
  util.arrayToEnum = items => {
    const obj = {};
    for (const item of items) {
      obj[item] = item;
    }
    return obj;
  };
  util.getValidEnumValues = obj => {
    const validKeys = util.objectKeys(obj).filter(k => typeof obj[obj[k]] !== "number");
    const filtered = {};
    for (const k of validKeys) {
      filtered[k] = obj[k];
    }
    return util.objectValues(filtered);
  };
  util.objectValues = obj => {
    return util.objectKeys(obj).map(function (e) {
      return obj[e];
    });
  };
  util.objectKeys = typeof Object.keys === "function" // eslint-disable-line ban/ban
  ? obj => Object.keys(obj) // eslint-disable-line ban/ban
  : object => {
    const keys = [];
    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        keys.push(key);
      }
    }
    return keys;
  };
  util.find = (arr, checker) => {
    for (const item of arr) {
      if (checker(item)) return item;
    }
    return undefined;
  };
  util.isInteger = typeof Number.isInteger === "function" ? val => Number.isInteger(val) // eslint-disable-line ban/ban
  : val => typeof val === "number" && isFinite(val) && Math.floor(val) === val;
  function joinValues(array, separator = " | ") {
    return array.map(val => typeof val === "string" ? `'${val}'` : val).join(separator);
  }
  util.joinValues = joinValues;
  util.jsonStringifyReplacer = (_, value) => {
    if (typeof value === "bigint") {
      return value.toString();
    }
    return value;
  };
})(util = exports.util || (exports.util = {}));
var objectUtil;
(function (objectUtil) {
  objectUtil.mergeShapes = (first, second) => {
    return {
      ...first,
      ...second // second overwrites first
    };
  };
})(objectUtil = exports.objectUtil || (exports.objectUtil = {}));
exports.ZodParsedType = util.arrayToEnum(["string", "nan", "number", "integer", "float", "boolean", "date", "bigint", "symbol", "function", "undefined", "null", "array", "object", "unknown", "promise", "void", "never", "map", "set"]);
const getParsedType = data => {
  const t = typeof data;
  switch (t) {
    case "undefined":
      return exports.ZodParsedType.undefined;
    case "string":
      return exports.ZodParsedType.string;
    case "number":
      return isNaN(data) ? exports.ZodParsedType.nan : exports.ZodParsedType.number;
    case "boolean":
      return exports.ZodParsedType.boolean;
    case "function":
      return exports.ZodParsedType.function;
    case "bigint":
      return exports.ZodParsedType.bigint;
    case "symbol":
      return exports.ZodParsedType.symbol;
    case "object":
      if (Array.isArray(data)) {
        return exports.ZodParsedType.array;
      }
      if (data === null) {
        return exports.ZodParsedType.null;
      }
      if (data.then && typeof data.then === "function" && data.catch && typeof data.catch === "function") {
        return exports.ZodParsedType.promise;
      }
      if (typeof Map !== "undefined" && data instanceof Map) {
        return exports.ZodParsedType.map;
      }
      if (typeof Set !== "undefined" && data instanceof Set) {
        return exports.ZodParsedType.set;
      }
      if (typeof Date !== "undefined" && data instanceof Date) {
        return exports.ZodParsedType.date;
      }
      return exports.ZodParsedType.object;
    default:
      return exports.ZodParsedType.unknown;
  }
};
exports.getParsedType = getParsedType;