class t extends Error {}
class e extends t {
  constructor(t) {
    super(`Invalid DateTime: ${t.toMessage()}`);
  }
}
class s extends t {
  constructor(t) {
    super(`Invalid Duration: ${t.toMessage()}`);
  }
}
class n extends t {
  constructor(t) {
    super(`Invalid Interval: ${t.toMessage()}`);
  }
}
class r extends t {
  constructor(t) {
    super(`Invalid unit ${t}`), Object.setPrototypeOf(this, r.prototype);
  }
}
class i extends t {
  constructor(t) {
    super(`${t} is an invalid or unknown zone specifier`), Object.setPrototypeOf(this, i.prototype);
  }
}
class o extends t {
  constructor(t) {
    super(t), Object.setPrototypeOf(this, o.prototype);
  }
}
class a extends t {
  constructor(t) {
    super(t), Object.setPrototypeOf(this, a.prototype);
  }
}
class u extends t {
  constructor() {
    super("Zone is an abstract class"), Object.setPrototypeOf(this, u.prototype);
  }
}
function c(...t) {
  t.length;
}
class l {
  get type() {
    throw new u();
  }
  get ianaName() {
    return this.name;
  }
  get name() {
    throw new u();
  }
  get isUniversal() {
    throw new u();
  }
  offsetName(t, e) {
    throw c(t, e), new u();
  }
  formatOffset(t, e) {
    throw c(t, e), new u();
  }
  offset(t) {
    throw c(t), new u();
  }
  equals(t) {
    throw c(t), new u();
  }
  get isValid() {
    throw new u();
  }
}
var h = Intl;
let d = {};
const m = {
  year: 0,
  month: 1,
  day: 2,
  era: 3,
  hour: 4,
  minute: 5,
  second: 6
};
let f = {};
class y extends l {
  constructor(t) {
    super(), this._zoneName = t, this._valid = y.isValidZone(t);
  }
  static create(t) {
    return f[t] || (f[t] = new y(t)), f[t];
  }
  static resetCache() {
    f = {}, d = {};
  }
  static isValidSpecifier(t) {
    return this.isValidZone(t);
  }
  static isValidZone(t) {
    if (!t) return !1;
    try {
      return new h.DateTimeFormat("en-US", {
        timeZone: t
      }).format(), !0;
    } catch (t) {
      return !1;
    }
  }
  get type() {
    return "iana";
  }
  get name() {
    return this._zoneName;
  }
  get isUniversal() {
    return !1;
  }
  offsetName(t, {
    format: e,
    locale: s
  } = {}) {
    return Wt(t, e, s, this.name);
  }
  formatOffset(t, e) {
    return Jt(this.offset(t), e);
  }
  offset(t) {
    const e = new Date(t);
    if (isNaN(+e)) return NaN;
    const s = function (t) {
      if (!d[t]) try {
        d[t] = new h.DateTimeFormat("en-US", {
          hour12: !1,
          timeZone: t,
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          era: "short"
        });
      } catch (e) {
        throw new i(t);
      }
      return d[t];
    }(this.name);
    let n;
    const [r, o, a, u, c, l, f] = typeof s.formatToParts == typeof isNaN ? function (t, e) {
      const s = t.formatToParts(e),
        n = [];
      for (let t = 0; t < s.length; t++) {
        const {
            type: e,
            value: r
          } = s[t],
          i = m[e];
        "era" === e ? n[i] = r : Mt(i) || (n[i] = parseInt(r, 10));
      }
      return n;
    }(s, e) : function (t, e) {
      const s = t.format(e).replace(/\u200E/g, ""),
        n = /(\d+)\/(\d+)\/(\d+) (AD|BC),? (\d+):(\d+):(\d+)/.exec(s),
        [, r, i, o, a, u, c, l] = n;
      return [o, r, i, a, u, c, l];
    }(s, e);
    "BC" === u && (n = 1 - Math.abs(+r));
    let y = +e;
    const _ = y % 1e3;
    return y -= _ >= 0 ? _ : 1e3 + _, (qt({
      year: n || +r,
      month: +o,
      day: +a,
      hour: +(24 === c ? 0 : c),
      minute: +l,
      second: +f,
      millisecond: 0
    }) - y) / 6e4;
  }
  equals(t) {
    return "iana" === t.type && t.name === this.name;
  }
  get isValid() {
    return this._valid;
  }
}
const _ = "numeric",
  g = "short",
  p = "long",
  w = {
    year: _,
    month: _,
    day: _
  },
  O = {
    year: _,
    month: g,
    day: _
  },
  b = {
    year: _,
    month: g,
    day: _,
    weekday: g
  },
  v = {
    year: _,
    month: p,
    day: _
  },
  T = {
    year: _,
    month: p,
    day: _,
    weekday: p
  },
  S = {
    hour: _,
    minute: _
  },
  k = {
    hour: _,
    minute: _,
    second: _
  },
  M = {
    hour: _,
    minute: _,
    second: _,
    timeZoneName: g
  },
  N = {
    hour: _,
    minute: _,
    second: _,
    timeZoneName: p
  },
  D = {
    hour: _,
    minute: _,
    hourCycle: "h23"
  },
  E = {
    hour: _,
    minute: _,
    second: _,
    hourCycle: "h23"
  },
  j = {
    hour: _,
    minute: _,
    second: _,
    hourCycle: "h23",
    timeZoneName: g
  },
  x = {
    hour: _,
    minute: _,
    second: _,
    hourCycle: "h23",
    timeZoneName: p
  },
  I = {
    year: _,
    month: _,
    day: _,
    hour: _,
    minute: _
  },
  V = {
    year: _,
    month: _,
    day: _,
    hour: _,
    minute: _,
    second: _
  },
  C = {
    year: _,
    month: g,
    day: _,
    hour: _,
    minute: _
  },
  Z = {
    year: _,
    month: g,
    day: _,
    hour: _,
    minute: _,
    second: _
  },
  F = {
    year: _,
    month: g,
    day: _,
    weekday: g,
    hour: _,
    minute: _
  },
  $ = {
    year: _,
    month: p,
    day: _,
    hour: _,
    minute: _,
    timeZoneName: g
  },
  z = {
    year: _,
    month: p,
    day: _,
    hour: _,
    minute: _,
    second: _,
    timeZoneName: g
  },
  L = {
    year: _,
    month: p,
    day: _,
    weekday: p,
    hour: _,
    minute: _,
    timeZoneName: p
  },
  q = {
    year: _,
    month: p,
    day: _,
    weekday: p,
    hour: _,
    minute: _,
    second: _,
    timeZoneName: p
  },
  A = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
  U = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  W = ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"];
function H(t) {
  switch (t) {
    case "narrow":
      return [...W];
    case "short":
      return [...U];
    case "long":
      return [...A];
    case "numeric":
      return ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
    case "2-digit":
      return ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
  }
}
const P = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
  R = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  J = ["M", "T", "W", "T", "F", "S", "S"];
function Y(t) {
  switch (t) {
    case "narrow":
      return [...J];
    case "short":
      return [...R];
    case "long":
      return [...P];
    case "numeric":
      return ["1", "2", "3", "4", "5", "6", "7"];
  }
}
const G = ["AM", "PM"],
  B = ["Before Christ", "Anno Domini"],
  Q = ["BC", "AD"],
  K = ["B", "A"];
function X(t) {
  switch (t) {
    case "narrow":
      return [...K];
    case "short":
      return [...Q];
    case "long":
      return [...B];
  }
}
let tt = {};
let et = {};
function st(t, e = {}) {
  const s = JSON.stringify([t, e]);
  let n = et[s];
  return n || (n = new h.DateTimeFormat(t, e), et[s] = n), n;
}
let nt = {};
let rt,
  it = {};
function ot(t, e, s, n) {
  return "en" === t.listingMode() ? s(e) : n(e);
}
class at {
  constructor(t, e, s) {
    this._padTo = s.padTo || 0, this._floor = s.floor || !1;
    const n = function (t, e) {
      var s = {};
      for (var n in t) Object.prototype.hasOwnProperty.call(t, n) && e.indexOf(n) < 0 && (s[n] = t[n]);
      if (null != t && "function" == typeof Object.getOwnPropertySymbols) {
        var r = 0;
        for (n = Object.getOwnPropertySymbols(t); r < n.length; r++) e.indexOf(n[r]) < 0 && Object.prototype.propertyIsEnumerable.call(t, n[r]) && (s[n[r]] = t[n[r]]);
      }
      return s;
    }(s, ["padTo", "floor"]);
    if (!e || Object.keys(n).length > 0) {
      const e = Object.assign({
        useGrouping: !1
      }, s);
      this._padTo > 0 && (e.minimumIntegerDigits = s.padTo), this._inf = function (t, e) {
        const s = JSON.stringify([t, e]);
        let n = nt[s];
        return n || (n = new h.NumberFormat(t, e), nt[s] = n), n;
      }(t, e);
    }
  }
  format(t) {
    if (this._inf) {
      const e = this._floor ? Math.floor(t) : t;
      return this._inf.format(e);
    }
    return It(this._floor ? Math.floor(t) : Ft(t, 3), this._padTo);
  }
}
class ut {
  constructor(t, e, s) {
    let n;
    if (this._opts = s, this._opts.timeZone) this._dt = t;else if ("fixed" === t.zone.type) {
      const e = t.offset / 60 * -1,
        s = e >= 0 ? `Etc/GMT+${e}` : `Etc/GMT${e}`;
      0 !== t.offset && y.create(s).isValid ? (n = s, this._dt = t) : (n = "UTC", this._dt = 0 === t.offset ? t : t.setZone("UTC").plus({
        minutes: t.offset
      }), this._originalZone = t.zone);
    } else "system" === t.zone.type ? this._dt = t : "iana" === t.zone.type ? (this._dt = t, n = t.zone.name) : (n = "UTC", this._dt = t.setZone("UTC").plus({
      minutes: t.offset
    }), this._originalZone = t.zone);
    const r = Object.assign(Object.assign({}, this._opts), {
      timeZone: this._opts.timeZone || n
    });
    this._dtf = st(e, r);
  }
  get dtf() {
    return this._dtf;
  }
  format() {
    return this._originalZone ? this.formatToParts().map(({
      value: t
    }) => t).join("") : this.dtf.format(this._dt.toJSDate());
  }
  formatToParts() {
    const t = this.dtf.formatToParts(this._dt.toJSDate());
    return this._originalZone ? t.map(t => {
      if ("timeZoneName" === t.type) {
        const e = this._originalZone.offsetName(this._dt.ts, {
          locale: this._dt.locale,
          format: this._opts.timeZoneName
        });
        return Object.assign(Object.assign({}, t), {
          value: e
        });
      }
      return t;
    }) : t;
  }
  resolvedOptions() {
    return this._dtf.resolvedOptions();
  }
}
class ct {
  constructor(t, e, s) {
    this._opts = Object.assign({
      style: "long"
    }, s), !e && Et() && (this._rtf = function (t, e = {}) {
      const s = JSON.stringify([t, e]);
      let n = it[s];
      return n || (n = new h.RelativeTimeFormat(t, e), it[s] = n), n;
    }(t, s));
  }
  format(t, e) {
    return this._rtf ? this._rtf.format(t, e) : function (t, e, s = "always", n = !1) {
      const r = Ye.normalizeUnit(t),
        i = {
          years: ["year", "yr."],
          quarters: ["quarter", "qtr."],
          months: ["month", "mo."],
          weeks: ["week", "wk."],
          days: ["day", "day", "days"],
          hours: ["hour", "hr."],
          minutes: ["minute", "min."],
          seconds: ["second", "sec."],
          milliseconds: []
        }[r],
        o = -1 === ["hours", "minutes", "seconds"].indexOf(r);
      if ("auto" === s && o) {
        const t = "days" === r;
        switch (e) {
          case 1:
            return t ? "tomorrow" : `next ${i[0]}`;
          case -1:
            return t ? "yesterday" : `last ${i[0]}`;
          case 0:
            return t ? "today" : `this ${i[0]}`;
        }
      }
      const a = Object.is(e, -0) || e < 0,
        u = Math.abs(e),
        c = 1 === u,
        l = n ? c ? i[1] : i[2] || i[1] : c ? i[0] : r;
      return a ? `${u} ${l} ago` : `in ${u} ${l}`;
    }(e, t, this._opts.numeric, "long" !== this._opts.style);
  }
  formatToParts(t, e) {
    return this._rtf ? this._rtf.formatToParts(t, e) : [];
  }
}
class lt {
  constructor(t, e, s, n) {
    const [r, i, o] = function (t) {
      const e = t.indexOf("-x-");
      -1 !== e && (t = t.substring(0, e));
      const s = t.indexOf("-u-");
      if (-1 === s) return [t];
      {
        let e, n;
        try {
          e = st(t).resolvedOptions(), n = t;
        } catch (r) {
          const i = t.substring(0, s);
          e = st(i).resolvedOptions(), n = i;
        }
        const {
          numberingSystem: r,
          calendar: i
        } = e;
        return [n, r, i];
      }
    }(t);
    this.locale = r, this.numberingSystem = e || i, this.outputCalendar = s || o, this._intl = function (t, e, s) {
      return s || e ? (t.includes("-u-") || (t += "-u"), s && (t += `-ca-${s}`), e && (t += `-nu-${e}`), t) : t;
    }(this.locale, this.numberingSystem, this.outputCalendar), this._weekdaysCache = {
      format: {},
      standalone: {}
    }, this._monthsCache = {
      format: {},
      standalone: {}
    }, this._meridiemCache = void 0, this._eraCache = {}, this._specifiedLocale = n, this._fastNumbersCached = void 0;
  }
  get fastNumbers() {
    return void 0 === this._fastNumbersCached && (this._fastNumbersCached = this._supportsFastNumbers()), this._fastNumbersCached;
  }
  static fromOpts(t) {
    return lt.create(t.locale, t.numberingSystem, t.outputCalendar, t.defaultToEN);
  }
  static create(t, e, s, n = !1) {
    const r = t || St.defaultLocale,
      i = r || (n ? "en-US" : (rt || (rt = new h.DateTimeFormat().resolvedOptions().locale), rt)),
      o = e || St.defaultNumberingSystem,
      a = s || St.defaultOutputCalendar;
    return new lt(i, o, a, r);
  }
  static resetCache() {
    rt = void 0, tt = {}, et = {}, nt = {}, it = {};
  }
  static fromObject({
    locale: t,
    numberingSystem: e,
    outputCalendar: s
  } = {}) {
    return lt.create(t, e, s);
  }
  listingMode() {
    const t = this.isEnglish(),
      e = !(null !== this.numberingSystem && "latn" !== this.numberingSystem || null !== this.outputCalendar && "gregory" !== this.outputCalendar);
    return t && e ? "en" : "intl";
  }
  clone(t) {
    return t && 0 !== Object.getOwnPropertyNames(t).length ? lt.create(t.locale || this._specifiedLocale, t.numberingSystem || this.numberingSystem, t.outputCalendar || this.outputCalendar, t.defaultToEN || !1) : this;
  }
  redefaultToEN(t = {}) {
    return this.clone(Object.assign(Object.assign({}, t), {
      defaultToEN: !0
    }));
  }
  redefaultToSystem(t = {}) {
    return this.clone(Object.assign(Object.assign({}, t), {
      defaultToEN: !1
    }));
  }
  months(t, e = !1) {
    return ot(this, t, H, t => {
      const s = e ? {
          month: t,
          day: "numeric"
        } : {
          month: t
        },
        n = e ? "format" : "standalone";
      return this._monthsCache[n][t] || (this._monthsCache[n][t] = function (t) {
        const e = [];
        for (let s = 1; s <= 12; s++) {
          const n = Js.utc(2016, s, 1);
          e.push(t(n));
        }
        return e;
      }(t => this.extract(t, s, "month"))), this._monthsCache[n][t];
    });
  }
  weekdays(t, e = !1) {
    return ot(this, t, Y, t => {
      const s = e ? {
          weekday: t,
          year: "numeric",
          month: "long",
          day: "numeric"
        } : {
          weekday: t
        },
        n = e ? "format" : "standalone";
      return this._weekdaysCache[n][t] || (this._weekdaysCache[n][t] = function (t) {
        const e = [];
        for (let s = 1; s <= 7; s++) {
          const n = Js.utc(2016, 11, 13 + s);
          e.push(t(n));
        }
        return e;
      }(t => this.extract(t, s, "weekday"))), this._weekdaysCache[n][t];
    });
  }
  meridiems() {
    return ot(this, "long", () => G, () => (void 0 === this._meridiemCache && (this._meridiemCache = [Js.utc(2016, 11, 13, 9), Js.utc(2016, 11, 13, 19)].map(t => this.extract(t, {
      hour: "numeric",
      hourCycle: "h12"
    }, "dayPeriod"))), this._meridiemCache));
  }
  eras(t) {
    return ot(this, t, X, t => {
      const e = {
        era: t
      };
      return this._eraCache[t] || (this._eraCache[t] = [Js.utc(-40, 1, 1), Js.utc(2017, 1, 1)].map(t => this.extract(t, e, "era"))), this._eraCache[t];
    });
  }
  extract(t, e, s) {
    const n = this.dtFormatter(t, e).formatToParts().find(t => t.type.toLowerCase() === s.toLowerCase());
    if (!n) throw new Error(`Invalid extract field ${s}`);
    return n.value;
  }
  numberFormatter(t = {}) {
    return new at(this._intl, this.fastNumbers, t);
  }
  dtFormatter(t, e = {}) {
    return new ut(t, this._intl, e);
  }
  relFormatter(t = {}) {
    return new ct(this._intl, this.isEnglish(), t);
  }
  listFormatter(t = {}) {
    return function (t, e = {}) {
      const s = JSON.stringify([t, e]);
      let n = tt[s];
      return n || (n = new h.ListFormat(t, e), tt[s] = n), n;
    }(this._intl, t);
  }
  isEnglish() {
    return !!~["en", "en-us"].indexOf(this.locale.toLowerCase()) || new h.DateTimeFormat(this._intl).resolvedOptions().locale.startsWith("en-us");
  }
  equals(t) {
    return this.locale === t.locale && this.numberingSystem === t.numberingSystem && this.outputCalendar === t.outputCalendar;
  }
  _supportsFastNumbers() {
    return (!this.numberingSystem || "latn" === this.numberingSystem) && ("latn" === this.numberingSystem || !this.locale || this.locale.startsWith("en") || "latn" === h.DateTimeFormat(this._intl).resolvedOptions().numberingSystem);
  }
}
let ht = null;
class dt extends l {
  constructor(t) {
    super(), this._fixed = t;
  }
  static get utcInstance() {
    return null === ht && (ht = new dt(0)), ht;
  }
  get isValid() {
    return !0;
  }
  get ianaName() {
    return 0 === this._fixed ? "Etc/UTC" : `Etc/GMT${Jt(-this._fixed, "narrow")}`;
  }
  get name() {
    return 0 === this._fixed ? "UTC" : `UTC${Jt(this._fixed, "narrow")}`;
  }
  get type() {
    return "fixed";
  }
  get isUniversal() {
    return !0;
  }
  static instance(t) {
    return 0 === t ? dt.utcInstance : new dt(t);
  }
  static parseSpecifier(t) {
    if (t) {
      const e = t.match(/^utc(?:([+-]\d{1,2})(?::(\d{2}))?)?$/i);
      if (e) return new dt(Ht(e[1], e[2]));
    }
    return null;
  }
  offsetName() {
    return this.name;
  }
  formatOffset(t, e) {
    return Jt(this._fixed, e);
  }
  offset() {
    return this._fixed;
  }
  equals(t) {
    return "fixed" === t.type && t._fixed === this._fixed;
  }
}
class mt extends l {
  constructor(t) {
    super(), this._zoneName = t, Object.setPrototypeOf(this, mt.prototype);
  }
  get type() {
    return "invalid";
  }
  get name() {
    return this._zoneName;
  }
  get isUniversal() {
    return !1;
  }
  offsetName() {
    return null;
  }
  formatOffset() {
    return "";
  }
  offset() {
    return NaN;
  }
  equals() {
    return !1;
  }
  get isValid() {
    return !1;
  }
}
let ft = null;
class yt extends l {
  static get instance() {
    return null === ft && (ft = new yt()), ft;
  }
  get type() {
    return "system";
  }
  get name() {
    return new Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  get isUniversal() {
    return !1;
  }
  offsetName(t, {
    format: e,
    locale: s
  }) {
    return Wt(t, e, s);
  }
  formatOffset(t, e) {
    return Jt(this.offset(t), e);
  }
  offset(t) {
    return -new Date(t).getTimezoneOffset();
  }
  equals(t) {
    return "system" === t.type;
  }
  get isValid() {
    return !0;
  }
}
const _t = (t, e) => {
  if (Mt(t) || null === t) return e;
  if (t instanceof l) return t;
  if ("string" == typeof t) {
    const s = t.toLowerCase();
    return "default" === s ? e : "local" === s || "system" === s ? yt.instance : "utc" === s || "gmt" === s ? dt.utcInstance : dt.parseSpecifier(s) || y.create(t);
  }
  return Nt(t) ? dt.instance(t) : "object" == typeof t && t.offset && "number" == typeof t.offset ? t : new mt(t);
};
let gt,
  pt,
  wt,
  Ot = () => Date.now(),
  bt = "system",
  vt = 60,
  Tt = !1;
class St {
  static get now() {
    return Ot;
  }
  static set now(t) {
    Ot = t;
  }
  static set defaultZoneLike(t) {
    bt = t;
  }
  static set defaultZone(t) {
    bt = t;
  }
  static get defaultZone() {
    return _t(bt, yt.instance);
  }
  static get defaultLocale() {
    return gt;
  }
  static set defaultLocale(t) {
    gt = t;
  }
  static get defaultNumberingSystem() {
    return pt;
  }
  static set defaultNumberingSystem(t) {
    pt = t;
  }
  static get defaultOutputCalendar() {
    return wt;
  }
  static set defaultOutputCalendar(t) {
    wt = t;
  }
  static get twoDigitCutoffYear() {
    return vt;
  }
  static set twoDigitCutoffYear(t) {
    vt = t % 100;
  }
  static get throwOnInvalid() {
    return Tt;
  }
  static set throwOnInvalid(t) {
    Tt = t;
  }
  static resetCaches() {
    lt.resetCache(), y.resetCache();
  }
}
function kt(t) {
  return void 0 !== t;
}
function Mt(t) {
  return void 0 === t;
}
function Nt(t) {
  return "number" == typeof t;
}
function Dt(t) {
  return Nt(t) && t % 1 == 0;
}
function Et() {
  try {
    return void 0 !== h && !!h.RelativeTimeFormat;
  } catch (t) {
    return !1;
  }
}
function jt(t, e, s) {
  if (0 === t.length) return;
  return t.reduce((t, n) => {
    const r = [e(n), n];
    return s(t[0], r[0]) === t[0] ? t : r;
  }, [e(t[0]), t[0]])[1];
}
function xt(t, e, s) {
  return Dt(t) && t >= e && t <= s;
}
function It(t, e = 2) {
  const s = t < 0 ? "-" : "",
    n = s ? -1 * +t : t;
  let r;
  return r = n.toString().length < e ? ("0".repeat(e) + n).slice(-e) : n.toString(), `${s}${r}`;
}
function Vt(t) {
  if (t) return parseInt(t, 10);
}
function Ct(t) {
  if (t) return parseFloat(t);
}
function Zt(t) {
  if (!Mt(t) && null !== t && "" !== t) {
    const e = 1e3 * parseFloat("0." + t);
    return Math.floor(e);
  }
}
function Ft(t, e, s = !1) {
  const n = Math.pow(10, e);
  return (s ? Math.trunc : Math.round)(t * n) / n;
}
function $t(t) {
  return t % 4 == 0 && (t % 100 != 0 || t % 400 == 0);
}
function zt(t) {
  return $t(t) ? 366 : 365;
}
function Lt(t, e) {
  const s = function (t, e) {
    return t - e * Math.floor(t / e);
  }(e - 1, 12) + 1;
  return [31, $t(t + (e - s) / 12) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][s - 1];
}
function qt(t) {
  let e = Date.UTC(t.year, t.month - 1, t.day, t.hour, t.minute, t.second, t.millisecond);
  return t.year < 100 && t.year >= 0 && (e = new Date(e), e.setUTCFullYear(t.year, t.month - 1, t.day)), +e;
}
function At(t) {
  const e = (t + Math.floor(t / 4) - Math.floor(t / 100) + Math.floor(t / 400)) % 7,
    s = t - 1,
    n = (s + Math.floor(s / 4) - Math.floor(s / 100) + Math.floor(s / 400)) % 7;
  return 4 === e || 3 === n ? 53 : 52;
}
function Ut(t) {
  return t > 99 ? t : t > St.twoDigitCutoffYear ? 1900 + t : 2e3 + t;
}
function Wt(t, e, s, n) {
  const r = new Date(t),
    i = {
      hourCycle: "h23",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: n
    },
    o = Object.assign({
      timeZoneName: e
    }, i),
    a = new h.DateTimeFormat(s, o).formatToParts(r).find(t => "timezonename" === t.type.toLowerCase());
  return a ? a.value : null;
}
function Ht(t, e) {
  let s = parseInt(t, 10);
  Number.isNaN(s) && (s = 0);
  const n = parseInt(e, 10) || 0;
  return 60 * s + (s < 0 || Object.is(s, -0) ? -n : n);
}
function Pt(t) {
  const e = Number(t);
  if ("boolean" == typeof t || "" === t || Number.isNaN(e)) throw new a(`Invalid unit value ${t}`);
  return e;
}
function Rt(t, e) {
  return Object.keys(t).reduce((s, n) => (void 0 !== t[n] && null !== t[n] && (s[e(n)] = Pt(t[n])), s), {});
}
function Jt(t, e) {
  const s = Math.trunc(Math.abs(t / 60)),
    n = Math.trunc(Math.abs(t % 60)),
    r = t >= 0 ? "+" : "-";
  switch (e) {
    case "short":
      return `${r}${It(s, 2)}:${It(n, 2)}`;
    case "narrow":
      return `${r}${s}${n > 0 ? `:${n}` : ""}`;
    case "techie":
      return `${r}${It(s, 2)}${It(n, 2)}`;
    default:
      throw new RangeError(`Value format ${e} is out of range for property format`);
  }
}
function Yt(t) {
  return function (t, e) {
    return e.reduce((e, s) => (e[s] = t[s], e), {});
  }(t, ["hour", "minute", "second", "millisecond"]);
}
const Gt = ["years", "quarters", "months", "weeks", "days", "hours", "minutes", "seconds", "milliseconds"],
  Bt = Gt.slice(0).reverse(),
  Qt = ["years", "months", "days", "hours", "minutes", "seconds", "milliseconds"];
function Kt(t, e) {
  let s = "";
  for (const n of t) n.literal ? s += n.val : s += e(n.val);
  return s;
}
const Xt = {
  D: w,
  DD: O,
  DDD: v,
  DDDD: T,
  t: S,
  tt: k,
  ttt: M,
  tttt: N,
  T: D,
  TT: E,
  TTT: j,
  TTTT: x,
  f: I,
  ff: C,
  fff: $,
  ffff: L,
  F: V,
  FF: Z,
  FFF: z,
  FFFF: q
};
class te {
  constructor(t, e) {
    this._opts = e, this._loc = t, this._systemLoc = void 0;
  }
  static create(t, e = {}) {
    return new te(t, e);
  }
  static parseFormat(t) {
    let e = null,
      s = "",
      n = !1;
    const r = [];
    for (let i = 0; i < t.length; i++) {
      const o = t.charAt(i);
      "'" === o ? (s.length > 0 && r.push({
        literal: n || /^\s+$/.test(s),
        val: s
      }), e = null, s = "", n = !n) : n || o === e ? s += o : (s.length > 0 && r.push({
        literal: /^\s+$/.test(s),
        val: s
      }), s = o, e = o);
    }
    return s.length > 0 && r.push({
      literal: n || /^\s+$/.test(s),
      val: s
    }), r;
  }
  static macroTokenToFormatOpts(t) {
    return Xt[t];
  }
  formatWithSystemDefault(t, e) {
    void 0 === this._systemLoc && (this._systemLoc = this._loc.redefaultToSystem());
    return this._systemLoc.dtFormatter(t, Object.assign(Object.assign({}, this._opts), e)).format();
  }
  dtFormatter(t, e = {}) {
    return this._loc.dtFormatter(t, Object.assign(Object.assign({}, this._opts), e));
  }
  formatDateTime(t, e) {
    return this.dtFormatter(t, e).format();
  }
  formatDateTimeParts(t, e) {
    return this.dtFormatter(t, e).formatToParts();
  }
  formatInterval(t, e = {}) {
    if (!t.isValid) throw Error("Invalid Interval provided!");
    return this.dtFormatter(t.start, e).dtf.formatRange(t.start.toJSDate(), t.end.toJSDate());
  }
  resolvedOptions(t, e = {}) {
    return this.dtFormatter(t, e).resolvedOptions();
  }
  num(t, e = 0) {
    if (this._opts.forceSimple) return It(t, e);
    const s = Object.assign({}, this._opts);
    return e > 0 && (s.padTo = e), this._loc.numberFormatter(s).format(t);
  }
  formatDateTimeFromString(t, e) {
    const s = "en" === this._loc.listingMode(),
      n = this._loc.outputCalendar && "gregory" !== this._loc.outputCalendar,
      r = (e, s) => this._loc.extract(t, e, s),
      i = e => t.isOffsetFixed && 0 === t.offset && e.allowZ ? "Z" : t.isValid ? t.zone.formatOffset(t.ts, e.format) : "",
      o = () => s ? function (t) {
        return G[t.hour < 12 ? 0 : 1];
      }(t) : r({
        hour: "numeric",
        hourCycle: "h12"
      }, "dayPeriod"),
      a = (e, n) => s ? function (t, e) {
        return H(e)[t.month - 1];
      }(t, e) : r(n ? {
        month: e
      } : {
        month: e,
        day: "numeric"
      }, "month"),
      u = (e, n) => s ? function (t, e) {
        return Y(e)[t.weekday - 1];
      }(t, e) : r(n ? {
        weekday: e
      } : {
        weekday: e,
        month: "long",
        day: "numeric"
      }, "weekday"),
      c = e => {
        const s = te.macroTokenToFormatOpts(e);
        return s ? this.formatWithSystemDefault(t, s) : e;
      },
      l = e => s ? function (t, e) {
        return X(e)[t.year < 0 ? 0 : 1];
      }(t, e) : r({
        era: e
      }, "era");
    return Kt(te.parseFormat(e), e => {
      switch (e) {
        case "S":
          return this.num(t.millisecond);
        case "u":
        case "SSS":
          return this.num(t.millisecond, 3);
        case "s":
          return this.num(t.second);
        case "ss":
          return this.num(t.second, 2);
        case "uu":
          return this.num(Math.floor(t.millisecond / 10), 2);
        case "uuu":
          return this.num(Math.floor(t.millisecond / 100));
        case "m":
          return this.num(t.minute);
        case "mm":
          return this.num(t.minute, 2);
        case "h":
          return this.num(t.hour % 12 == 0 ? 12 : t.hour % 12);
        case "hh":
          return this.num(t.hour % 12 == 0 ? 12 : t.hour % 12, 2);
        case "H":
          return this.num(t.hour);
        case "HH":
          return this.num(t.hour, 2);
        case "Z":
          return i({
            format: "narrow",
            allowZ: this._opts.allowZ
          });
        case "ZZ":
          return i({
            format: "short",
            allowZ: this._opts.allowZ
          });
        case "ZZZ":
          return i({
            format: "techie",
            allowZ: this._opts.allowZ
          });
        case "ZZZZ":
          return t.zone.offsetName(t.ts, {
            format: "short",
            locale: this._loc.locale
          }) || "";
        case "ZZZZZ":
          return t.zone.offsetName(t.ts, {
            format: "long",
            locale: this._loc.locale
          }) || "";
        case "z":
          return t.zoneName || "";
        case "a":
          return o();
        case "d":
          return n ? r({
            day: "numeric"
          }, "day") : this.num(t.day);
        case "dd":
          return n ? r({
            day: "2-digit"
          }, "day") : this.num(t.day, 2);
        case "c":
        case "E":
          return this.num(t.weekday);
        case "ccc":
          return u("short", !0);
        case "cccc":
          return u("long", !0);
        case "ccccc":
          return u("narrow", !0);
        case "EEE":
          return u("short", !1);
        case "EEEE":
          return u("long", !1);
        case "EEEEE":
          return u("narrow", !1);
        case "L":
          return n ? r({
            month: "numeric",
            day: "numeric"
          }, "month") : this.num(t.month);
        case "LL":
          return n ? r({
            month: "2-digit",
            day: "numeric"
          }, "month") : this.num(t.month, 2);
        case "LLL":
          return a("short", !0);
        case "LLLL":
          return a("long", !0);
        case "LLLLL":
          return a("narrow", !0);
        case "M":
          return n ? r({
            month: "numeric"
          }, "month") : this.num(t.month);
        case "MM":
          return n ? r({
            month: "2-digit"
          }, "month") : this.num(t.month, 2);
        case "MMM":
          return a("short", !1);
        case "MMMM":
          return a("long", !1);
        case "MMMMM":
          return a("narrow", !1);
        case "y":
          return n ? r({
            year: "numeric"
          }, "year") : this.num(t.year);
        case "yy":
          return n ? r({
            year: "2-digit"
          }, "year") : this.num(parseInt(t.year.toString().slice(-2), 10), 2);
        case "yyyy":
          return n ? r({
            year: "numeric"
          }, "year") : this.num(t.year, 4);
        case "yyyyyy":
          return n ? r({
            year: "numeric"
          }, "year") : this.num(t.year, 6);
        case "G":
          return l("short");
        case "GG":
          return l("long");
        case "GGGGG":
          return l("narrow");
        case "kk":
          return this.num(parseInt(t.weekYear.toString().slice(-2), 10), 2);
        case "kkkk":
          return this.num(t.weekYear, 4);
        case "W":
          return this.num(t.weekNumber);
        case "WW":
          return this.num(t.weekNumber, 2);
        case "o":
          return this.num(t.ordinal);
        case "ooo":
          return this.num(t.ordinal, 3);
        case "q":
          return this.num(t.quarter);
        case "qq":
          return this.num(t.quarter, 2);
        case "X":
          return this.num(Math.floor(t.ts / 1e3));
        case "x":
          return this.num(t.ts);
        default:
          return c(e);
      }
    });
  }
  formatDurationFromString(t, e) {
    const s = t => {
        switch (t[0]) {
          case "S":
            return "milliseconds";
          case "s":
            return "seconds";
          case "m":
            return "minutes";
          case "h":
            return "hours";
          case "d":
            return "days";
          case "M":
            return "months";
          case "y":
            return "years";
          default:
            return;
        }
      },
      n = te.parseFormat(e),
      r = n.reduce((t, {
        literal: e,
        val: s
      }) => e ? t : t.concat(s), []);
    return Kt(n, (t => e => {
      const n = s(e);
      return n ? this.num(t.get(n), e.length) : e;
    })(t.shiftTo(...r.map(s).filter(t => !!t))));
  }
}
function ee(...t) {
  const e = t.reduce((t, e) => t + e.source, "");
  return RegExp(`^${e}$`);
}
function se(...t) {
  return e => t.reduce(([t, s, n], r) => {
    const [i, o, a] = r(e, n);
    return [Object.assign(Object.assign({}, t), i), o || s, a];
  }, [{}, null, 1]).slice(0, 2);
}
function ne(t, ...e) {
  if (null == t) return [null, null];
  for (const [s, n] of e) {
    const e = s.exec(t);
    if (e) return n(e);
  }
  return [null, null];
}
function re(...t) {
  return (e, s) => {
    const n = {};
    let r;
    for (r = 0; r < t.length; r++) n[t[r]] = Vt(e[s + r]);
    return [n, null, s + r];
  };
}
const ie = /[A-Za-z_+-]{1,256}(?::?\/[A-Za-z0-9_+-]{1,256}(?:\/[A-Za-z0-9_+-]{1,256})?)?/,
  oe = /(?:(Z)|([+-]\d\d)(?::?(\d\d))?)/,
  ae = /(\d\d)(?::?(\d\d)(?::?(\d\d)(?:[.,](\d{1,30}))?)?)?/,
  ue = RegExp(`${ae.source}${`(?:${oe.source}?(?:\\[(${ie.source})\\])?)?`}`),
  ce = RegExp(`(?:T${ue.source})?`),
  le = re("weekYear", "weekNumber", "weekday"),
  he = re("year", "ordinal"),
  de = RegExp(`${ae.source} ?(?:${oe.source}|(${ie.source}))?`),
  me = RegExp(`(?: ${de.source})?`);
function fe(t, e, s) {
  return Mt(t[e]) ? s : Vt(t[e]);
}
function ye(t, e) {
  return [{
    hour: fe(t, e, 0),
    minute: fe(t, e + 1, 0),
    second: fe(t, e + 2, 0),
    millisecond: Zt(t[e + 3])
  }, null, e + 4];
}
function _e(t, e) {
  const s = !t[e] && !t[e + 1],
    n = Ht(t[e + 1], t[e + 2]);
  return [{}, s ? null : dt.instance(n), e + 3];
}
function ge(t, e) {
  return [{}, t[e] ? y.create(t[e]) : null, e + 1];
}
const pe = RegExp(`^T?${ae.source}$`),
  we = /^-?P(?:(?:(-?\d{1,20}(?:\.\d{1,20})?)Y)?(?:(-?\d{1,20}(?:\.\d{1,20})?)M)?(?:(-?\d{1,20}(?:\.\d{1,20})?)W)?(?:(-?\d{1,20}(?:\.\d{1,20})?)D)?(?:T(?:(-?\d{1,20}(?:\.\d{1,20})?)H)?(?:(-?\d{1,20}(?:\.\d{1,20})?)M)?(?:(-?\d{1,20})(?:[.,](-?\d{1,20}))?S)?)?)$/;
function Oe(t) {
  const [e, s, n, r, i, o, a, u, c] = t,
    l = e.startsWith("-"),
    h = !!u && u.startsWith("-"),
    d = (t, e = !1) => "number" == typeof t && (e || t && l) ? -t : t;
  return [{
    years: d(Ct(s)),
    months: d(Ct(n)),
    weeks: d(Ct(r)),
    days: d(Ct(i)),
    hours: d(Ct(o)),
    minutes: d(Ct(a)),
    seconds: d(Ct(u), "-0" === u),
    milliseconds: d(Zt(c), h)
  }];
}
const be = {
  GMT: 0,
  EDT: -240,
  EST: -300,
  CDT: -300,
  CST: -360,
  MDT: -360,
  MST: -420,
  PDT: -420,
  PST: -480
};
function ve(t, e, s, n, r, i, o) {
  let a;
  t && (a = t.length > 3 ? P.indexOf(t) + 1 : R.indexOf(t) + 1);
  return {
    year: 2 === e.length ? Ut(Vt(e)) : Vt(e),
    month: U.indexOf(s) + 1,
    day: Vt(n),
    hour: Vt(r),
    minute: Vt(i),
    second: Vt(o),
    weekday: a
  };
}
const Te = /^(?:(Mon|Tue|Wed|Thu|Fri|Sat|Sun),\s)?(\d{1,2})\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s(\d{2,4})\s(\d\d):(\d\d)(?::(\d\d))?\s(?:(UT|GMT|[ECMP][SD]T)|([Zz])|(?:([+-]\d\d)(\d\d)))$/;
function Se(t) {
  const [, e, s, n, r, i, o, a, u, c, l, h] = t,
    d = ve(e, r, n, s, i, o, a);
  let m;
  return m = u ? be[u] : c ? 0 : Ht(l, h), [d, new dt(m)];
}
const ke = /^(Mon|Tue|Wed|Thu|Fri|Sat|Sun), (\d\d) (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) (\d{4}) (\d\d):(\d\d):(\d\d) GMT$/,
  Me = /^(Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday), (\d\d)-(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)-(\d\d) (\d\d):(\d\d):(\d\d) GMT$/,
  Ne = /^(Mon|Tue|Wed|Thu|Fri|Sat|Sun) (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) ( \d|\d\d) (\d\d):(\d\d):(\d\d) (\d{4})$/;
function De(t) {
  const [, e, s, n, r, i, o, a] = t;
  return [ve(e, r, n, s, i, o, a), dt.utcInstance];
}
function Ee(t) {
  const [, e, s, n, r, i, o, a] = t;
  return [ve(e, a, s, n, r, i, o), dt.utcInstance];
}
const je = ee(/([+-]\d{6}|\d{4})(?:-?(\d\d)(?:-?(\d\d))?)?/, ce),
  xe = ee(/(\d{4})-?W(\d\d)(?:-?(\d))?/, ce),
  Ie = ee(/(\d{4})-?(\d{3})/, ce),
  Ve = ee(ue),
  Ce = se(function (t, e) {
    return [{
      year: fe(t, e, 0),
      month: fe(t, e + 1, 1),
      day: fe(t, e + 2, 1)
    }, null, e + 3];
  }, ye, _e, ge),
  Ze = se(le, ye, _e, ge),
  Fe = se(he, ye, _e, ge),
  $e = se(ye, _e, ge);
const ze = ee(/(\d{4})-(\d\d)-(\d\d)/, me),
  Le = ee(de),
  qe = se(ye, _e, ge);
class Ae {
  constructor(t, e) {
    this.reason = t, this.explanation = e, this._formattedExplanation = "", e && (this._formattedExplanation = `: ${e}`);
  }
  toMessage() {
    return `${this.reason}${this._formattedExplanation}`;
  }
}
const Ue = {
    weeks: {
      days: 7,
      hours: 168,
      minutes: 10080,
      seconds: 604800,
      milliseconds: 6048e5
    },
    days: {
      hours: 24,
      minutes: 1440,
      seconds: 86400,
      milliseconds: 864e5
    },
    hours: {
      minutes: 60,
      seconds: 3600,
      milliseconds: 36e5
    },
    minutes: {
      seconds: 60,
      milliseconds: 6e4
    },
    seconds: {
      milliseconds: 1e3
    }
  },
  We = Object.assign({
    years: {
      quarters: 4,
      months: 12,
      weeks: 52,
      days: 365,
      hours: 8760,
      minutes: 525600,
      seconds: 31536e3,
      milliseconds: 31536e6
    },
    quarters: {
      months: 3,
      weeks: 13,
      days: 91,
      hours: 2184,
      minutes: 131040,
      seconds: 7862400,
      milliseconds: 78624e5
    },
    months: {
      weeks: 4,
      days: 30,
      hours: 720,
      minutes: 43200,
      seconds: 2592e3,
      milliseconds: 2592e6
    }
  }, Ue),
  He = 365.2425,
  Pe = 30.436875,
  Re = Object.assign({
    years: {
      quarters: 4,
      months: 12,
      weeks: 52.1775,
      days: He,
      hours: 8765.82,
      minutes: 525949.2,
      seconds: 525949.2 * 60,
      milliseconds: 525949.2 * 60 * 1e3
    },
    quarters: {
      months: 3,
      weeks: 13.044375,
      days: 91.310625,
      hours: 2191.455,
      minutes: 131487.3,
      seconds: 525949.2 * 60 / 4,
      milliseconds: 7889237999.999999
    },
    months: {
      weeks: 4.3481250000000005,
      days: Pe,
      hours: 730.485,
      minutes: 43829.1,
      seconds: 2629746,
      milliseconds: 2629746e3
    }
  }, Ue);
function Je(t, e, s, n, r) {
  const i = t[r][s],
    o = e[s] / i,
    a = !(Math.sign(o) === Math.sign(n[r])) && 0 !== n[r] && Math.abs(o) <= 1 ? function (t) {
      return t < 0 ? Math.floor(t) : Math.ceil(t);
    }(o) : Math.trunc(o);
  n[r] = n[r] + a, e[s] = e[s] - a * i;
}
class Ye {
  constructor(t) {
    let e, s;
    "longterm" === t.conversionAccuracy || !1 ? (s = "longterm", e = Re) : (s = "casual", e = We), t.matrix && (e = t.matrix), this._values = t.values || {}, this._loc = t.loc || lt.create(), this._conversionAccuracy = s, this._invalid = t.invalid || null, this._matrix = e, this._isLuxonDuration = !0;
  }
  get conversionAccuracy() {
    return this._conversionAccuracy;
  }
  get invalidExplanation() {
    return this._invalid ? this._invalid.explanation : null;
  }
  get invalidReason() {
    return this._invalid ? this._invalid.reason : null;
  }
  get isValid() {
    return null === this._invalid;
  }
  get locale() {
    return this.isValid ? this._loc.locale : void 0;
  }
  get matrix() {
    return this._matrix;
  }
  get numberingSystem() {
    return this.isValid ? this._loc.numberingSystem : void 0;
  }
  static fromISOTime(t, e = {}) {
    const [s] = function (t) {
      return ne(t, [pe, se(ye)]);
    }(t);
    return s ? Ye.fromObject(s, e) : Ye.invalid("unparsable", `the input "${t}" can't be parsed as ISO 8601`);
  }
  static fromMillis(t, e = {}) {
    return Ye.fromObject({
      milliseconds: t
    }, e);
  }
  static fromObject(t, e = {}) {
    if (null == t || "object" != typeof t) throw new a("Duration.fromObject: argument expected to be an object, got " + (null === t ? "null" : typeof t));
    return new Ye({
      values: Rt(t, Ye.normalizeUnit),
      loc: lt.fromObject(e),
      conversionAccuracy: e.conversionAccuracy,
      matrix: e.matrix
    });
  }
  static fromDurationLike(t) {
    if (Nt(t)) return Ye.fromMillis(t);
    if (Ye.isDuration(t)) return t;
    if ("object" == typeof t) return Ye.fromObject(t);
    throw new a(`Unknown duration argument ${t} of type ${typeof t}`);
  }
  static fromISO(t, e) {
    const [s] = function (t) {
      return ne(t, [we, Oe]);
    }(t);
    return s ? Ye.fromObject(s, e) : Ye.invalid("unparsable", `the input "${t}" can't be parsed as ISO 8601`);
  }
  static isDuration(t) {
    return !!t && t._isLuxonDuration || !1;
  }
  static invalid(t, e) {
    if (!t) throw new a("need to specify a reason the Duration is invalid");
    const n = t instanceof Ae ? t : new Ae(t, e);
    if (St.throwOnInvalid) throw new s(n);
    return new Ye({
      invalid: n
    });
  }
  static normalizeUnit(t) {
    const e = {
      year: "years",
      years: "years",
      quarter: "quarters",
      quarters: "quarters",
      month: "months",
      months: "months",
      week: "weeks",
      weeks: "weeks",
      day: "days",
      days: "days",
      hour: "hours",
      hours: "hours",
      minute: "minutes",
      minutes: "minutes",
      second: "seconds",
      seconds: "seconds",
      millisecond: "milliseconds",
      milliseconds: "milliseconds"
    }[t];
    if (!e) throw new r(t);
    return e;
  }
  getMaxUnit(t = !1) {
    const e = t ? Qt : Gt,
      s = this.shiftTo(...e).toObject();
    return e.find(t => (s[t] || 0) > 0) || Bt[0];
  }
  toFormat(t, e = {
    floor: !0
  }) {
    const s = Object.assign(Object.assign({}, e), {
      floor: !1 !== e.round && !1 !== e.floor
    });
    return this.isValid ? te.create(this._loc, s).formatDurationFromString(this, t) : "Invalid Duration";
  }
  toHuman(t = {}) {
    const e = this.getMaxUnit(!0),
      s = t.onlyHumanUnits ? Qt : Gt,
      n = this.shiftTo(...s.slice(s.indexOf(e))).toObject(),
      r = s.map(e => {
        const s = n[e];
        return Mt(s) || 0 === s ? null : this._loc.numberFormatter(Object.assign(Object.assign({
          style: "unit",
          unitDisplay: "long"
        }, t), {
          unit: e.slice(0, -1)
        })).format(s);
      }).filter(t => t),
      i = Object.assign({
        type: "conjunction",
        style: t.listStyle || "narrow"
      }, t);
    return this._loc.listFormatter(i).format(r);
  }
  toObject() {
    return this.isValid ? Object.assign({}, this._values) : {};
  }
  toISO() {
    if (!this.isValid) return null;
    let t = "P";
    return 0 !== this.years && (t += this.years + "Y"), 0 === this.months && 0 === this.quarters || (t += this.months + 3 * this.quarters + "M"), 0 !== this.weeks && (t += this.weeks + "W"), 0 !== this.days && (t += this.days + "D"), 0 === this.hours && 0 === this.minutes && 0 === this.seconds && 0 === this.milliseconds || (t += "T"), 0 !== this.hours && (t += this.hours + "H"), 0 !== this.minutes && (t += this.minutes + "M"), 0 === this.seconds && 0 === this.milliseconds || (t += Ft(this.seconds + this.milliseconds / 1e3, 3) + "S"), "P" === t && (t += "T0S"), t;
  }
  toISOTime(t = {}) {
    if (!this.isValid) return null;
    const e = this.toMillis();
    if (e < 0 || e >= 864e5) return null;
    t = Object.assign(Object.assign({
      suppressMilliseconds: !1,
      suppressSeconds: !1,
      includePrefix: !1,
      format: "extended"
    }, t), {
      includeOffset: !1
    });
    return Js.fromMillis(e, {
      zone: "UTC"
    }).toISOTime(t);
  }
  toJSON() {
    return this.toISO();
  }
  toString() {
    return this.toISO();
  }
  toMillis() {
    return this.as("milliseconds");
  }
  valueOf() {
    return this.toMillis();
  }
  plus(t) {
    if (!this.isValid) return this;
    const e = Ye.fromDurationLike(t),
      s = {};
    return Gt.forEach(t => {
      void 0 === e._values[t] && void 0 === this._values[t] || (s[t] = e.get(t) + this.get(t));
    }), this._clone(this, {
      values: s
    }, !0);
  }
  minus(t) {
    if (!this.isValid) return this;
    const e = Ye.fromDurationLike(t);
    return this.plus(e.negate());
  }
  mapUnits(t) {
    if (!this.isValid) return this;
    const e = {};
    return Object.keys(this._values).forEach(s => {
      e[s] = Pt(t(this._values[s], s));
    }), this._clone(this, {
      values: e
    }, !0);
  }
  get(t) {
    return this[Ye.normalizeUnit(t)];
  }
  set(t) {
    if (!this.isValid) return this;
    const e = Object.assign(Object.assign({}, this._values), Rt(t, Ye.normalizeUnit));
    return this._clone(this, {
      values: e
    });
  }
  reconfigure({
    locale: t,
    numberingSystem: e,
    conversionAccuracy: s,
    matrix: n
  } = {}) {
    const r = {
      loc: this._loc.clone({
        locale: t,
        numberingSystem: e
      }),
      matrix: n,
      conversionAccuracy: s
    };
    return this._clone(this, r);
  }
  as(t) {
    return this.shiftTo(t).get(t);
  }
  normalize() {
    if (!this.isValid) return this;
    const t = this.toObject();
    return function (t, e) {
      let s;
      Bt.forEach(n => {
        Mt(e[n]) || (s && Je(t, e, s, e, n), s = n);
      });
    }(this._matrix, t), this._clone(this, {
      values: t
    }, !0);
  }
  rescale() {
    if (!this.isValid) return this;
    const t = function (t = {}) {
      return Object.entries(t).reduce((t, [e, s]) => (0 !== s && (t[e] = s), t), {});
    }(this.normalize().shiftToAll().toObject());
    return this._clone(this, {
      values: t
    }, !0);
  }
  shiftTo(...t) {
    if (!this.isValid || 0 === t.length) return this;
    t = t.map(t => Ye.normalizeUnit(t));
    const e = {},
      s = {},
      n = this.toObject();
    let r;
    return Gt.forEach(i => {
      if (t.indexOf(i) >= 0) {
        r = i;
        let t = 0;
        Object.keys(s).forEach(e => {
          t += this._matrix[e][i] * s[e], s[e] = 0;
        }), Nt(n[i]) && (t += n[i]);
        const o = Math.trunc(t);
        e[i] = o, s[i] = (1e3 * t - 1e3 * o) / 1e3, Object.keys(n).forEach(t => {
          Gt.indexOf(t) > Gt.indexOf(i) && Je(this._matrix, n, t, e, i);
        });
      } else Nt(n[i]) && (s[i] = n[i]);
    }), Object.keys(s).forEach(t => {
      const n = s[t];
      0 !== n && (e[r] += t === r ? n : n / this._matrix[r][t]);
    }), this._clone(this, {
      values: e
    }, !0).normalize();
  }
  shiftToAll() {
    return this.isValid ? this.shiftTo("years", "months", "weeks", "days", "hours", "minutes", "seconds", "milliseconds") : this;
  }
  negate() {
    if (!this.isValid) return this;
    const t = {};
    return Object.keys(this._values).forEach(e => {
      t[e] = 0 === this._values[e] ? 0 : -this._values[e];
    }), this._clone(this, {
      values: t
    }, !0);
  }
  get years() {
    return this.isValid ? this._values.years || 0 : NaN;
  }
  get quarters() {
    return this.isValid ? this._values.quarters || 0 : NaN;
  }
  get months() {
    return this.isValid ? this._values.months || 0 : NaN;
  }
  get weeks() {
    return this.isValid ? this._values.weeks || 0 : NaN;
  }
  get days() {
    return this.isValid ? this._values.days || 0 : NaN;
  }
  get hours() {
    return this.isValid ? this._values.hours || 0 : NaN;
  }
  get minutes() {
    return this.isValid ? this._values.minutes || 0 : NaN;
  }
  get seconds() {
    return this.isValid ? this._values.seconds || 0 : NaN;
  }
  get milliseconds() {
    return this.isValid ? this._values.milliseconds || 0 : NaN;
  }
  equals(t) {
    if (!this.isValid || !t.isValid) return !1;
    if (!this._loc.equals(t._loc)) return !1;
    for (const n of Gt) if (e = this._values[n], s = t._values[n], !(void 0 === e || 0 === e ? void 0 === s || 0 === s : e === s)) return !1;
    var e, s;
    return !0;
  }
  _clone(t, e, s = !1) {
    const n = {
      values: s ? e.values : Object.assign(Object.assign({}, t._values), e.values || {}),
      loc: t._loc.clone(e.loc),
      conversionAccuracy: e.conversionAccuracy || t.conversionAccuracy,
      matrix: e.matrix || t.matrix
    };
    return new Ye(n);
  }
}
const Ge = "Invalid Interval";
function Be(t) {
  if (Js.isDateTime(t)) return t;
  if (t && t.valueOf && Nt(t.valueOf())) return Js.fromJSDate(t);
  if (t && "object" == typeof t) return Js.fromObject(t);
  throw new a(`Unknown datetime argument: ${t}, of type ${typeof t}`);
}
class Qe {
  constructor(t) {
    this._s = t.start, this._e = t.end, this._invalid = t.invalid || null, this._isLuxonInterval = !0;
  }
  get invalidReason() {
    return this._invalid ? this._invalid.reason : null;
  }
  get isValid() {
    return null === this.invalidReason;
  }
  get start() {
    return this.isValid ? this._s : null;
  }
  get end() {
    return this.isValid ? this._e : null;
  }
  static fromDateTimes(t, e) {
    const s = Be(t),
      n = Be(e),
      r = function (t, e) {
        return t && t.isValid ? e && e.isValid ? e < t ? Qe.invalid("end before start", `The end of an interval must be after its start, but you had start=${t.toISO()} and end=${e.toISO()}`) : void 0 : Qe.invalid("missing or invalid end") : Qe.invalid("missing or invalid start");
      }(s, n);
    return r || new Qe({
      start: s,
      end: n
    });
  }
  static after(t, e) {
    const s = Ye.fromDurationLike(e),
      n = Be(t);
    return new Qe({
      start: n,
      end: n ? n.plus(s) : void 0
    });
  }
  static before(t, e) {
    const s = Ye.fromDurationLike(e),
      n = Be(t);
    return new Qe({
      start: n ? n.minus(s) : void 0,
      end: n
    });
  }
  static fromISO(t, e = {}) {
    const [s, n] = (t || "").split("/", 2);
    if (s && n) {
      let t, r, i, o;
      try {
        t = Js.fromISO(s, e), r = t.isValid;
      } catch (n) {
        r = !1;
      }
      try {
        i = Js.fromISO(n, e), o = i.isValid;
      } catch (n) {
        o = !1;
      }
      if (r && o) return Qe.fromDateTimes(t, i);
      if (r) {
        const s = Ye.fromISO(n, e);
        if (s.isValid) return Qe.after(t, s);
      } else if (o) {
        const t = Ye.fromISO(s, e);
        if (t.isValid) return Qe.before(i, t);
      }
    }
    return Qe.invalid("unparsable", `the input "${t}" can't be parsed as ISO 8601`);
  }
  static invalid(t, e) {
    if (!t) throw new a("need to specify a reason the Interval is invalid");
    const s = t instanceof Ae ? t : new Ae(t, e);
    if (St.throwOnInvalid) throw new n(s);
    return new Qe({
      invalid: s
    });
  }
  static isInterval(t) {
    return !!t && t._isLuxonInterval || !1;
  }
  static merge(t) {
    const [e, s] = t.sort((t, e) => t._s.valueOf() - e._s.valueOf()).reduce(([t, e], s) => e ? e.overlaps(s) || e.abutsStart(s) ? [t, e.union(s)] : [t.concat([e]), s] : [t, s], [[], null]);
    return s && e.push(s), e;
  }
  static xor(t) {
    let e = null,
      s = 0;
    const n = [],
      r = t.map(t => [{
        time: t._s,
        type: "s"
      }, {
        time: t._e,
        type: "e"
      }]),
      i = Array.prototype.concat(...r).sort((t, e) => +t.time - +e.time);
    for (const t of i) s += "s" === t.type ? 1 : -1, 1 === s ? e = t.time : (e && e.valueOf() !== t.time.valueOf() && n.push(Qe.fromDateTimes(e, t.time)), e = null);
    return Qe.merge(n);
  }
  length(t = "milliseconds") {
    return this.toDuration(t).get(t);
  }
  count(t = "milliseconds") {
    if (!this.isValid) return NaN;
    const e = this.start.startOf(t),
      s = this.end.startOf(t);
    return Math.floor(s.diff(e, t).get(t)) + +(s.valueOf() !== this.end.valueOf());
  }
  hasSame(t) {
    return !!this.isValid && (this.isEmpty() || this._e.minus(1).hasSame(this._s, t));
  }
  isEmpty() {
    return this._s.valueOf() === this._e.valueOf();
  }
  isAfter(t) {
    return !!this.isValid && this._s > t;
  }
  isBefore(t) {
    return !!this.isValid && this._e <= t;
  }
  contains(t) {
    return this._s <= t && this._e > t;
  }
  set({
    start: t,
    end: e
  } = {}) {
    return this.isValid ? Qe.fromDateTimes(t || this._s, e || this._e) : this;
  }
  splitAt(...t) {
    const e = t.map(Be).filter(t => this.contains(t)).sort(),
      s = [];
    let n = this._s,
      r = 0;
    for (; n < this._e;) {
      const t = e[r] || this._e,
        i = +t > +this._e ? this._e : t;
      s.push(Qe.fromDateTimes(n, i)), n = i, r += 1;
    }
    return s;
  }
  splitBy(t) {
    const e = Ye.fromDurationLike(t);
    if (!this.isValid || !e.isValid || 0 === e.as("milliseconds")) return [];
    let s,
      n = this._s,
      r = 1;
    const i = [];
    for (; n < this._e;) {
      const t = this.start.plus(e.mapUnits(t => t * r));
      s = +t > +this._e ? this._e : t, i.push(Qe.fromDateTimes(n, s)), n = s, r += 1;
    }
    return i;
  }
  divideEqually(t) {
    return this.isValid ? this.splitBy({
      milliseconds: this.length() / t
    }).slice(0, t) : [];
  }
  overlaps(t) {
    return this._e > t._s && this._s < t._e;
  }
  abutsStart(t) {
    return +this._e == +t._s;
  }
  abutsEnd(t) {
    return +t._e == +this._s;
  }
  engulfs(t) {
    return !!this.isValid && this._s <= t._s && this._e >= t._e;
  }
  equals(t) {
    return !(!this.isValid || !t.isValid) && this._s.equals(t._s) && this._e.equals(t._e);
  }
  intersection(t) {
    if (!this.isValid) return this;
    const e = this._s > t._s ? this._s : t._s,
      s = this._e < t._e ? this._e : t._e;
    return e >= s ? null : Qe.fromDateTimes(e, s);
  }
  union(t) {
    if (!this.isValid) return this;
    const e = this._s < t._s ? this._s : t._s,
      s = this._e > t._e ? this._e : t._e;
    return Qe.fromDateTimes(e, s);
  }
  difference(...t) {
    return Qe.xor([this].concat(t)).map(t => this.intersection(t)).filter(t => t && !t.isEmpty());
  }
  toString() {
    return this.isValid ? `[${this._s.toISO()} – ${this._e.toISO()})` : Ge;
  }
  toLocaleString(t = w, e = {}) {
    return this.isValid ? te.create(this._s.loc.clone(e), t).formatInterval(this) : Ge;
  }
  toISO(t = {}) {
    return this.isValid ? `${this._s.toISO(t)}/${this._e.toISO(t)}` : Ge;
  }
  toISODate() {
    return this.isValid ? `${this._s.toISODate()}/${this._e.toISODate()}` : Ge;
  }
  toISOTime(t = {}) {
    return this.isValid ? `${this._s.toISOTime(t)}/${this._e.toISOTime(t)}` : Ge;
  }
  toFormat(t, {
    separator: e = " - "
  } = {}) {
    return this.isValid ? `${this._s.toFormat(t)}${e}${this._e.toFormat(t)}` : Ge;
  }
  toDuration(t = "milliseconds", e = {}) {
    return this.isValid ? this._e.diff(this._s, t, e) : Ye.invalid(this._invalid.reason);
  }
  mapEndpoints(t) {
    return Qe.fromDateTimes(t(this._s), t(this._e));
  }
}
class Ke {
  static hasDST(t = St.defaultZone) {
    const e = Js.now().setZone(t).set({
      month: 12
    });
    return !t.isUniversal && e.offset !== e.set({
      month: 6
    }).offset;
  }
  static isValidIANAZone(t) {
    return y.isValidZone(t);
  }
  static normalizeZone(t) {
    return _t(t, St.defaultZone);
  }
  static months(t = "long", {
    locale: e,
    locObj: s,
    numberingSystem: n,
    outputCalendar: r = "gregory"
  } = {}) {
    return (s || lt.create(e, n, r)).months(t);
  }
  static monthsFormat(t = "long", {
    locale: e,
    locObj: s,
    numberingSystem: n,
    outputCalendar: r = "gregory"
  } = {}) {
    return (s || lt.create(e, n, r)).months(t, !0);
  }
  static weekdays(t = "long", {
    locale: e,
    locObj: s,
    numberingSystem: n
  } = {}) {
    return (s || lt.create(e, n)).weekdays(t);
  }
  static weekdaysFormat(t = "long", {
    locale: e,
    locObj: s,
    numberingSystem: n
  } = {}) {
    return (s || lt.create(e, n)).weekdays(t, !0);
  }
  static meridiems({
    locale: t
  } = {}) {
    return lt.create(t).meridiems();
  }
  static eras(t = "short", {
    locale: e
  } = {}) {
    return lt.create(e, void 0, "gregory").eras(t);
  }
  static features() {
    return {
      relative: Et()
    };
  }
}
function Xe(t, e) {
  const s = t => t.toUTC(0, {
      keepLocalTime: !0
    }).startOf("days").valueOf(),
    n = s(e) - s(t);
  return Math.floor(Ye.fromMillis(n).as("days"));
}
const ts = (t, e, s, n) => {
    let [r, i, o, a] = function (t, e, s) {
      const n = [["years", (t, e) => e.year - t.year], ["quarters", (t, e) => e.quarter - t.quarter + 4 * (e.year - t.year)], ["months", (t, e) => e.month - t.month + 12 * (e.year - t.year)], ["weeks", (t, e) => {
          const s = Xe(t, e);
          return (s - s % 7) / 7;
        }], ["days", Xe]],
        r = {},
        i = t;
      let o, a;
      for (const [u, c] of n) s.indexOf(u) >= 0 && (o = u, r[u] = c(t, e), a = i.plus(r), a > e ? (r[u]--, (t = i.plus(r)) > e && (a = t, r[u]--, t = i.plus(r))) : t = a);
      return [t, r, a, o];
    }(t, e, s);
    const u = +e - +r,
      c = s.filter(t => ["hours", "minutes", "seconds", "milliseconds"].indexOf(t) >= 0);
    0 === c.length && (o < e && (o = r.plus({
      [a]: 1
    })), o !== r && (i[a] = (i[a] || 0) + u / (+o - +r)));
    const l = Ye.fromObject(i, n);
    return c.length > 0 ? Ye.fromMillis(u, n).shiftTo(...c).plus(l) : l;
  },
  es = {
    arab: "[٠-٩]",
    arabext: "[۰-۹]",
    bali: "[᭐-᭙]",
    beng: "[০-৯]",
    deva: "[०-९]",
    fullwide: "[０-９]",
    gujr: "[૦-૯]",
    hanidec: "[〇|一|二|三|四|五|六|七|八|九]",
    khmr: "[០-៩]",
    knda: "[೦-೯]",
    laoo: "[໐-໙]",
    limb: "[᥆-᥏]",
    mlym: "[൦-൯]",
    mong: "[᠐-᠙]",
    mymr: "[၀-၉]",
    orya: "[୦-୯]",
    tamldec: "[௦-௯]",
    telu: "[౦-౯]",
    thai: "[๐-๙]",
    tibt: "[༠-༩]",
    latn: "\\d"
  },
  ss = {
    arab: [1632, 1641],
    arabext: [1776, 1785],
    bali: [6992, 7001],
    beng: [2534, 2543],
    deva: [2406, 2415],
    fullwide: [65296, 65303],
    gujr: [2790, 2799],
    khmr: [6112, 6121],
    knda: [3302, 3311],
    laoo: [3792, 3801],
    limb: [6470, 6479],
    mlym: [3430, 3439],
    mong: [6160, 6169],
    mymr: [4160, 4169],
    orya: [2918, 2927],
    tamldec: [3046, 3055],
    telu: [3174, 3183],
    thai: [3664, 3673],
    tibt: [3872, 3881]
  },
  ns = es.hanidec.replace(/[\[|\]]/g, "").split("");
function rs(t, e = "") {
  return new RegExp(`${es[t.numberingSystem || "latn"]}${e}`);
}
const is = "missing Intl.DateTimeFormat.formatToParts support";
function os(t, e = t => t) {
  return {
    regex: t,
    deser: ([t]) => e(function (t) {
      const e = parseInt(t, 10);
      if (!isNaN(e)) return e;
      let s = "";
      for (let e = 0; e < t.length; e++) {
        const n = t.charCodeAt(e);
        if (-1 !== t[e].search(es.hanidec)) s += ns.indexOf(t[e]);else for (const t in ss) {
          const [e, r] = ss[t];
          if (n >= e && n <= r) {
            s += n - e;
            break;
          }
        }
      }
      return parseInt(s, 10);
    }(t))
  };
}
const as = `[ ${String.fromCharCode(160)}]`,
  us = new RegExp(as, "g");
function cs(t) {
  return t.replace(/\./g, "\\.?").replace(us, as);
}
function ls(t) {
  return t.replace(/\./g, "").replace(us, " ").toLowerCase();
}
function hs(t, e) {
  return {
    regex: RegExp(t.map(cs).join("|")),
    deser: ([s]) => t.findIndex(t => ls(s) === ls(t)) + e
  };
}
function ds(t, e) {
  return {
    regex: t,
    deser: ([, t, e]) => Ht(t, e),
    groups: e
  };
}
function ms(t) {
  return {
    regex: t,
    deser: ([t]) => t
  };
}
const fs = {
  year: {
    "2-digit": "yy",
    numeric: "yyyyy"
  },
  month: {
    numeric: "M",
    "2-digit": "MM",
    short: "MMM",
    long: "MMMM"
  },
  day: {
    numeric: "d",
    "2-digit": "dd"
  },
  weekday: {
    short: "EEE",
    long: "EEEE"
  },
  dayPeriod: "a",
  hour12: {
    numeric: "h",
    "2-digit": "hh"
  },
  hour24: {
    numeric: "H",
    "2-digit": "HH"
  },
  hour: {
    numeric: "h",
    "2-digit": "hh"
  },
  minute: {
    numeric: "m",
    "2-digit": "mm"
  },
  second: {
    numeric: "s",
    "2-digit": "ss"
  },
  timeZoneName: {
    long: "ZZZZZ",
    short: "ZZZ"
  }
};
let ys;
function _s(t) {
  return !!t && !!t.invalidReason;
}
function gs(t, e) {
  return Array.prototype.concat(...t.map(t => function (t, e) {
    if (t.literal) return t;
    const s = Os(te.macroTokenToFormatOpts(t.val), e);
    return null == s || s.includes(void 0) ? t : s;
  }(t, e)));
}
function ps(t, e, s) {
  const n = gs(te.parseFormat(s), t),
    r = n.map(e => function (t, e) {
      const s = rs(e),
        n = rs(e, "{2}"),
        r = rs(e, "{3}"),
        i = rs(e, "{4}"),
        o = rs(e, "{6}"),
        a = rs(e, "{1,2}"),
        u = rs(e, "{1,3}"),
        c = rs(e, "{1,6}"),
        l = rs(e, "{1,9}"),
        h = rs(e, "{2,4}"),
        d = rs(e, "{4,6}"),
        m = t => {
          return {
            regex: RegExp((e = t.val, e.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, "\\$&"))),
            deser: ([t]) => t,
            literal: !0
          };
          var e;
        },
        f = (f => {
          if (t.literal) return m(f);
          switch (f.val) {
            case "G":
              return hs(e.eras("short"), 0);
            case "GG":
              return hs(e.eras("long"), 0);
            case "y":
              return os(c);
            case "yy":
            case "kk":
              return os(h, Ut);
            case "yyyy":
            case "kkkk":
              return os(i);
            case "yyyyy":
              return os(d);
            case "yyyyyy":
              return os(o);
            case "M":
            case "L":
            case "d":
            case "H":
            case "h":
            case "m":
            case "q":
            case "s":
            case "W":
              return os(a);
            case "MM":
            case "LL":
            case "dd":
            case "HH":
            case "hh":
            case "mm":
            case "qq":
            case "ss":
            case "WW":
              return os(n);
            case "MMM":
              return hs(e.months("short", !0), 1);
            case "MMMM":
              return hs(e.months("long", !0), 1);
            case "LLL":
              return hs(e.months("short", !1), 1);
            case "LLLL":
              return hs(e.months("long", !1), 1);
            case "o":
            case "S":
              return os(u);
            case "ooo":
            case "SSS":
              return os(r);
            case "u":
              return ms(l);
            case "a":
              return hs(e.meridiems(), 0);
            case "E":
            case "c":
              return os(s);
            case "EEE":
              return hs(e.weekdays("short", !1), 1);
            case "EEEE":
              return hs(e.weekdays("long", !1), 1);
            case "ccc":
              return hs(e.weekdays("short", !0), 1);
            case "cccc":
              return hs(e.weekdays("long", !0), 1);
            case "Z":
            case "ZZ":
              return ds(new RegExp(`([+-]${a.source})(?::(${n.source}))?`), 2);
            case "ZZZ":
              return ds(new RegExp(`([+-]${a.source})(${n.source})?`), 2);
            case "z":
              return ms(/[a-z_+-/]{1,256}?/i);
            default:
              return m(f);
          }
        })(t) || {
          invalidReason: is
        };
      return Object.assign(Object.assign({}, f), {
        token: t
      });
    }(e, t)),
    i = r.find(_s);
  if (i) return {
    input: e,
    tokens: n,
    invalidReason: i.invalidReason
  };
  {
    const t = function (t) {
        return `^${t.map(t => t.regex).reduce((t, e) => `${t}(${e.source})`, "")}$`;
      }(r),
      s = RegExp(t, "i"),
      [i, a] = function (t, e, s) {
        const n = e.exec(t),
          r = {};
        if (null !== n) {
          let t = 1;
          s.forEach(e => {
            const s = e.groups ? e.groups + 1 : 1;
            e.literal || (r[e.token.val[0]] = e.deser(n.slice(t, t + s))), t += s;
          });
        }
        return [n, r];
      }(e, s, r),
      [u, c, l] = a ? function (t) {
        let e,
          s = null;
        return kt(t.z) && (s = y.create(t.z)), kt(t.Z) && (s || (s = new dt(+t.Z)), e = +t.Z), Mt(t.q) || (t.M = 3 * (t.q - 1) + 1), Mt(t.h) || (t.h < 12 && 1 === t.a ? t.h = t.h + 12 : 12 === t.h && 0 === t.a && (t.h = 0)), 0 === t.G && t.y && (t.y = -t.y), Mt(t.u) || (t.S = Zt(t.u) || 0), [Object.keys(t).reduce((e, s) => {
          const n = (t => {
            switch (t) {
              case "S":
                return "millisecond";
              case "s":
                return "second";
              case "m":
                return "minute";
              case "h":
              case "H":
                return "hour";
              case "d":
                return "day";
              case "o":
                return "ordinal";
              case "L":
              case "M":
                return "month";
              case "y":
                return "year";
              case "E":
              case "c":
                return "weekday";
              case "W":
                return "weekNumber";
              case "k":
                return "weekYear";
              case "q":
                return "quarter";
              default:
                return null;
            }
          })(s);
          return n && (e[n] = t[s]), e;
        }, {}), s, e];
      }(a) : [null, null, void 0];
    if ("a" in a && "H" in a) throw new o("Can't include meridiem when specifying 24-hour format");
    return {
      input: e,
      tokens: n,
      regex: s,
      rawMatches: i,
      matches: a,
      result: u,
      zone: c,
      specificOffset: l
    };
  }
}
function ws(t) {
  return t.replace(/\u202F/g, " ");
}
function Os(t, e) {
  if (!t) return null;
  const s = te.create(e, t).dtFormatter(function (t) {
      return void 0 === ys && (ys = Js.fromMillis(1555555555555, {
        locale: t.locale
      })), ys;
    }(e)),
    n = s.formatToParts(),
    r = s.resolvedOptions();
  return n.map(e => function (t, e, s) {
    const {
      type: n,
      value: r
    } = t;
    if ("literal" === n) {
      const t = /^\s+$/.test(r);
      return {
        literal: !t,
        val: t ? " " : r
      };
    }
    const i = e[n];
    let o = n;
    "hour" === n && (o = null != e.hour12 ? e.hour12 ? "hour12" : "hour24" : null != e.hourCycle ? "h11" === e.hourCycle || "h12" === e.hourCycle ? "hour12" : "hour24" : s.hour12 ? "hour12" : "hour24");
    let a = fs[o];
    if ("object" == typeof a && (a = a[i]), a) return {
      literal: !1,
      val: a
    };
  }(e, t, r));
}
const bs = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334],
  vs = [0, 31, 60, 91, 121, 152, 182, 213, 244, 274, 305, 335];
function Ts(t, e) {
  return new Ae("unit out of range", `you specified ${e} (of type ${typeof e}) as a ${t}, which is invalid`);
}
function Ss(t, e, s) {
  const n = new Date(Date.UTC(t, e - 1, s));
  t < 100 && t >= 0 && n.setUTCFullYear(n.getUTCFullYear() - 1900);
  const r = n.getUTCDay();
  return 0 === r ? 7 : r;
}
function ks(t, e, s) {
  return s + ($t(t) ? vs : bs)[e - 1];
}
function Ms(t, e) {
  const s = $t(t) ? vs : bs,
    n = s.findIndex(t => t < e);
  return {
    month: n + 1,
    day: e - s[n]
  };
}
function Ns(t) {
  const {
      year: e,
      month: s,
      day: n
    } = t,
    r = ks(e, s, n),
    i = Ss(e, s, n);
  let o,
    a = Math.floor((r - i + 10) / 7);
  return a < 1 ? (o = e - 1, a = At(o)) : a > At(e) ? (o = e + 1, a = 1) : o = e, Object.assign({
    weekYear: o,
    weekNumber: a,
    weekday: i
  }, Yt(t));
}
function Ds(t) {
  const {
      weekYear: e,
      weekNumber: s,
      weekday: n
    } = t,
    r = Ss(e, 1, 4),
    i = zt(e);
  let o,
    a = 7 * s + n - r - 3;
  a < 1 ? (o = e - 1, a += zt(o)) : a > i ? (o = e + 1, a -= zt(e)) : o = e;
  const {
    month: u,
    day: c
  } = Ms(o, a);
  return Object.assign({
    year: o,
    month: u,
    day: c
  }, Yt(t));
}
function Es(t) {
  const {
      year: e,
      month: s,
      day: n
    } = t,
    r = ks(e, s, n);
  return Object.assign({
    year: e,
    ordinal: r
  }, Yt(t));
}
function js(t) {
  const {
      year: e,
      ordinal: s
    } = t,
    {
      month: n,
      day: r
    } = Ms(e, s);
  return Object.assign({
    year: e,
    month: n,
    day: r
  }, Yt(t));
}
function xs(t) {
  const e = Dt(t.year),
    s = xt(t.month, 1, 12),
    n = xt(t.day, 1, Lt(t.year, t.month));
  return e ? s ? !n && Ts("day", t.day) : Ts("month", t.month) : Ts("year", t.year);
}
function Is(t) {
  const {
      hour: e,
      minute: s,
      second: n,
      millisecond: r
    } = t,
    i = xt(e, 0, 23) || 24 === e && 0 === s && 0 === n && 0 === r,
    o = xt(s, 0, 59),
    a = xt(n, 0, 59),
    u = xt(r, 0, 999);
  return i ? o ? a ? !u && Ts("millisecond", r) : Ts("second", n) : Ts("minute", s) : Ts("hour", e);
}
const Vs = "Invalid DateTime",
  Cs = 864e13;
function Zs(t, e, s) {
  let n = t - 60 * e * 1e3;
  const r = s.offset(n);
  if (e === r) return [n, e];
  n -= 60 * (r - e) * 1e3;
  const i = s.offset(n);
  return r === i ? [n, r] : [t - 60 * Math.min(r, i) * 1e3, Math.max(r, i)];
}
function Fs(t, e) {
  const s = new Date(t += 60 * e * 1e3);
  return {
    year: s.getUTCFullYear(),
    month: s.getUTCMonth() + 1,
    day: s.getUTCDate(),
    hour: s.getUTCHours(),
    minute: s.getUTCMinutes(),
    second: s.getUTCSeconds(),
    millisecond: s.getUTCMilliseconds()
  };
}
function $s(t, e, s) {
  return Zs(qt(t), e, s);
}
function zs(t, e, s, n, r, i) {
  const {
    setZone: o,
    zone: a
  } = s;
  if (t && 0 !== Object.keys(t).length || e) {
    const n = e || a,
      r = Js.fromObject(t || void 0, Object.assign(Object.assign({}, s), {
        zone: n,
        specificOffset: i
      }));
    return o ? r : r.setZone(a);
  }
  return Js.invalid(new Ae("unparsable", `the input "${r}" can't be parsed as ${n}`));
}
function Ls(t, e, s = !0) {
  return t.isValid ? te.create(lt.create("en-US"), {
    allowZ: s,
    forceSimple: !0
  }).formatDateTimeFromString(t, e) : null;
}
const qs = {
    year: 0,
    month: 1,
    day: 1,
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0
  },
  As = {
    weekNumber: 1,
    weekday: 1,
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0
  },
  Us = {
    ordinal: 1,
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0
  },
  Ws = ["year", "month", "day", "hour", "minute", "second", "millisecond"],
  Hs = ["weekYear", "weekNumber", "weekday", "hour", "minute", "second", "millisecond"],
  Ps = ["year", "ordinal", "hour", "minute", "second", "millisecond"];
function Rs(t) {
  const e = {
    year: "year",
    years: "year",
    quarter: "quarter",
    quarters: "quarter",
    month: "month",
    months: "month",
    day: "day",
    days: "day",
    hour: "hour",
    hours: "hour",
    minute: "minute",
    minutes: "minute",
    second: "second",
    seconds: "second",
    millisecond: "millisecond",
    milliseconds: "millisecond",
    weekday: "weekday",
    weekdays: "weekday",
    weeknumber: "weekNumber",
    weeksnumber: "weekNumber",
    weeknumbers: "weekNumber",
    weekyear: "weekYear",
    weekyears: "weekYear",
    ordinal: "ordinal"
  }[t.toLowerCase()];
  if (!e) throw new r(t);
  return e;
}
class Js {
  constructor(t) {
    const e = t.zone || St.defaultZone;
    let s,
      n,
      r = t.invalid || (Number.isNaN(t.ts) ? new Ae("invalid timestamp") : null) || (e.isValid ? null : Js._unsupportedZone(e));
    if (this._ts = Mt(t.ts) ? St.now() : t.ts, !r) {
      if (!!t.old && t.old.ts === this._ts && t.old.zone.equals(e)) [n, s] = [t.old.c, t.old.o];else {
        const t = e.offset(this._ts);
        n = Fs(this._ts, t), r = Number.isNaN(n.year) ? new Ae("invalid input") : null, n = r ? void 0 : n, s = r ? void 0 : t;
      }
    }
    this._zone = e, this._loc = t.loc || lt.create(), this._invalid = r, this._weekData = null, this._c = n, this._o = s, this._isLuxonDateTime = !0;
  }
  get invalidExplanation() {
    return this._invalid ? this._invalid.explanation : void 0;
  }
  get invalidReason() {
    return this._invalid ? this._invalid.reason : void 0;
  }
  get isValid() {
    return null === this._invalid;
  }
  get loc() {
    return this.isValid ? this._loc.clone() : void 0;
  }
  get locale() {
    return this.isValid ? this._loc.locale : void 0;
  }
  get numberingSystem() {
    return this.isValid ? this._loc.numberingSystem : void 0;
  }
  get outputCalendar() {
    return this.isValid ? this._loc.outputCalendar : void 0;
  }
  get zone() {
    return this._zone;
  }
  get zoneName() {
    return this.isValid ? this.zone.name : null;
  }
  get year() {
    return this.isValid ? this._c.year : NaN;
  }
  get quarter() {
    return this.isValid ? Math.ceil(this._c.month / 3) : NaN;
  }
  get month() {
    return this.isValid ? this._c.month : NaN;
  }
  get day() {
    return this.isValid ? this._c.day : NaN;
  }
  get hour() {
    return this.isValid ? this._c.hour : NaN;
  }
  get minute() {
    return this.isValid ? this._c.minute : NaN;
  }
  get second() {
    return this.isValid ? this._c.second : NaN;
  }
  get millisecond() {
    return this.isValid ? this._c.millisecond : NaN;
  }
  get weekYear() {
    return this.isValid ? this._possiblyCachedWeekData(this).weekYear : NaN;
  }
  get weekNumber() {
    return this.isValid ? this._possiblyCachedWeekData(this).weekNumber : NaN;
  }
  get weekday() {
    return this.isValid ? this._possiblyCachedWeekData(this).weekday : NaN;
  }
  get ordinal() {
    return this.isValid ? Es(this._c).ordinal : NaN;
  }
  get monthShort() {
    return this.isValid ? Ke.months("short", {
      locObj: this._loc
    })[this.month - 1] : null;
  }
  get monthLong() {
    return this.isValid ? Ke.months("long", {
      locObj: this._loc
    })[this.month - 1] : null;
  }
  get weekdayShort() {
    return this.isValid ? Ke.weekdays("short", {
      locObj: this._loc
    })[this.weekday - 1] : null;
  }
  get weekdayLong() {
    return this.isValid ? Ke.weekdays("long", {
      locObj: this._loc
    })[this.weekday - 1] : null;
  }
  get offset() {
    return this.isValid ? +this._o : NaN;
  }
  get offsetNameShort() {
    return this.isValid ? this.zone.offsetName(this._ts, {
      format: "short",
      locale: this.locale
    }) : null;
  }
  get offsetNameLong() {
    return this.isValid ? this.zone.offsetName(this._ts, {
      format: "long",
      locale: this.locale
    }) : null;
  }
  get isOffsetFixed() {
    return this.isValid ? this.zone.isUniversal : null;
  }
  get isInDST() {
    return !this.isOffsetFixed && (this.offset > this.set({
      month: 1,
      day: 1
    }).offset || this.offset > this.set({
      month: 5
    }).offset);
  }
  get isInLeapYear() {
    return $t(this.year);
  }
  get daysInMonth() {
    return Lt(this.year, this.month);
  }
  get daysInYear() {
    return this.isValid ? zt(this.year) : NaN;
  }
  get weeksInWeekYear() {
    return this.isValid ? At(this.weekYear) : NaN;
  }
  get ts() {
    return this._ts;
  }
  static now() {
    return new Js({});
  }
  static local(...t) {
    const [e, s] = this._lastOpts(t),
      [n, r, i, o, a, u, c] = s;
    return Js._quickDT({
      year: n,
      month: r,
      day: i,
      hour: o,
      minute: a,
      second: u,
      millisecond: c
    }, e);
  }
  static utc(...t) {
    const [e, s] = this._lastOpts(t),
      [n, r, i, o, a, u, c] = s;
    return e.zone = dt.utcInstance, this._quickDT({
      year: n,
      month: r,
      day: i,
      hour: o,
      minute: a,
      second: u,
      millisecond: c
    }, e);
  }
  static fromJSDate(t, e = {}) {
    const s = (n = t, "[object Date]" === Object.prototype.toString.call(n) ? t.valueOf() : NaN);
    var n;
    if (Number.isNaN(s)) return Js.invalid("invalid input");
    const r = _t(e.zone, St.defaultZone);
    return r.isValid ? new Js({
      ts: s,
      zone: r,
      loc: lt.fromObject(e)
    }) : Js.invalid(Js._unsupportedZone(r));
  }
  static fromMillis(t, e = {}) {
    if (Nt(t)) return t < -Cs || t > Cs ? Js.invalid("Timestamp out of range") : new Js({
      ts: t,
      zone: _t(e.zone, St.defaultZone),
      loc: lt.fromObject(e)
    });
    throw new a(`fromMillis requires a numerical input, but received a ${typeof t} with value ${t}`);
  }
  static fromSeconds(t, e = {}) {
    if (!Nt(t)) throw new a("fromSeconds requires a numerical input");
    return new Js({
      ts: 1e3 * t,
      zone: _t(e.zone, St.defaultZone),
      loc: lt.fromObject(e)
    });
  }
  static fromObject(t = {}, e = {}) {
    const s = _t(e.zone, St.defaultZone);
    if (!s.isValid) return Js.invalid(Js._unsupportedZone(s));
    const n = St.now(),
      r = Nt(e.specificOffset) ? e.specificOffset : s.offset(n),
      i = Rt(t, Rs),
      a = kt(i.ordinal),
      u = kt(i.year),
      c = kt(i.month) || kt(i.day),
      l = u || c,
      h = i.weekYear || i.weekNumber,
      d = lt.fromObject(e);
    if ((l || a) && h) throw new o("Can't mix weekYear/weekNumber units with year/month/day or ordinals");
    if (c && a) throw new o("Can't mix ordinal dates with month/day");
    const m = h || i.weekday && !l,
      f = Fs(n, r),
      y = {
        containsGregor: l,
        containsOrdinal: a,
        loc: d,
        normalized: i,
        obj: t,
        offsetProvis: r,
        useWeekData: m,
        zoneToUse: s
      };
    return m ? Js._buildObject(y, Hs, As, Ns(f)) : a ? Js._buildObject(y, Ps, Us, Es(f)) : Js._buildObject(y, Ws, qs, f);
  }
  static fromISO(t, e = {}) {
    const [s, n] = function (t) {
      return ne(t, [je, Ce], [xe, Ze], [Ie, Fe], [Ve, $e]);
    }(t);
    return zs(s, n, e, "ISO 8601", t);
  }
  static fromRFC2822(t, e = {}) {
    const [s, n] = function (t) {
      return ne(function (t) {
        return t.replace(/\([^()]*\)|[\n\t]/g, " ").replace(/(\s\s+)/g, " ").trim();
      }(t), [Te, Se]);
    }(t);
    return zs(s, n, e, "RFC 2822", t);
  }
  static fromHTTP(t, e = {}) {
    const [s, n] = function (t) {
      return ne(t, [ke, De], [Me, De], [Ne, Ee]);
    }(t);
    return zs(s, n, e, "HTTP", t);
  }
  static fromFormat(t, e, s = {}) {
    if (Mt(t) || Mt(e)) throw new a("fromFormat requires an input string and a format");
    const {
        locale: n,
        numberingSystem: r
      } = s,
      i = lt.fromOpts({
        locale: n,
        numberingSystem: r,
        defaultToEN: !0
      }),
      [o, u, c, l] = function (t, e, s) {
        const {
          result: n,
          zone: r,
          specificOffset: i,
          invalidReason: o
        } = ps(t, ws(e), ws(s));
        return [n, r, i, o];
      }(i, t, e);
    return l ? Js.invalid(l) : zs(o, u || null, s, `format ${e}`, t, c);
  }
  static fromString(t, e, s = {}) {
    return Js.fromFormat(t, e, s);
  }
  static fromSQL(t, e = {}) {
    const [s, n] = function (t) {
      return ne(t, [ze, Ce], [Le, qe]);
    }(t);
    return zs(s, n, e, "SQL", t);
  }
  static invalid(t, s) {
    if (!t) throw new a("need to specify a reason the DateTime is invalid");
    const n = t instanceof Ae ? t : new Ae(t, s);
    if (St.throwOnInvalid) throw new e(n);
    return new Js({
      invalid: n
    });
  }
  static isDateTime(t) {
    return !(!t || !t._isLuxonDateTime);
  }
  static parseFormatForOpts(t, e = {}) {
    const s = Os(t, lt.fromObject(e));
    return s ? s.map(t => t ? t.val : null).join("") : null;
  }
  static min(...t) {
    if (!t.every(Js.isDateTime)) throw new a("min requires all arguments be DateTimes");
    return jt(t, t => t.valueOf(), Math.min);
  }
  static max(...t) {
    if (!t.every(Js.isDateTime)) throw new a("max requires all arguments be DateTimes");
    return jt(t, t => t.valueOf(), Math.max);
  }
  static fromFormatExplain(t, e, s = {}) {
    const {
      locale: n,
      numberingSystem: r
    } = s;
    return ps(lt.fromOpts({
      locale: n,
      numberingSystem: r,
      defaultToEN: !0
    }), t, e);
  }
  static fromStringExplain(t, e, s = {}) {
    return Js.fromFormatExplain(t, e, s);
  }
  static expandFormat(t, e = {}) {
    return gs(te.parseFormat(t), lt.fromObject(e)).map(t => t.val).join("");
  }
  static _buildObject(t, e, s, n) {
    let r = !1;
    e.forEach(e => {
      kt(t.normalized[e]) ? r = !0 : t.normalized[e] = r ? s[e] : n[e];
    });
    const i = (t.useWeekData ? function (t) {
      const e = Dt(t.weekYear),
        s = xt(t.weekNumber, 1, At(t.weekYear)),
        n = xt(t.weekday, 1, 7);
      return e ? s ? !n && Ts("weekday", t.weekday) : Ts("week", t.weekNumber) : Ts("weekYear", t.weekYear);
    }(t.normalized) : t.containsOrdinal ? function (t) {
      const e = Dt(t.year),
        s = xt(t.ordinal, 1, zt(t.year));
      return e ? !s && Ts("ordinal", t.ordinal) : Ts("year", t.year);
    }(t.normalized) : xs(t.normalized)) || Is(t.normalized);
    if (i) return Js.invalid(i);
    const o = t.useWeekData ? Ds(t.normalized) : t.containsOrdinal ? js(t.normalized) : t.normalized,
      [a, u] = $s(o, t.offsetProvis, t.zoneToUse),
      c = new Js({
        ts: a,
        zone: t.zoneToUse,
        o: u,
        loc: t.loc
      });
    return t.normalized.weekday && t.containsGregor && t.obj.weekday !== c.weekday ? Js.invalid("mismatched weekday", `you can't specify both a weekday of ${t.normalized.weekday} and a date of ${c.toISO()}`) : c;
  }
  static _lastOpts(t) {
    let e,
      s = {};
    return t.length > 0 && "object" == typeof t[t.length - 1] ? (s = t.pop(), e = t) : e = Array.from(t), [s, e];
  }
  static _quickDT(t, e) {
    const s = _t(e.zone, St.defaultZone),
      n = lt.fromObject(e),
      r = St.now();
    let i, o;
    if (kt(t.year)) {
      for (const e of Ws) Mt(t[e]) && (t[e] = qs[e]);
      const e = xs(t) || Is(t);
      if (e) return Js.invalid(e);
      const n = s.offset(r);
      [i, o] = $s(t, n, s);
    } else i = r;
    return new Js({
      ts: i,
      zone: s,
      loc: n,
      o: o
    });
  }
  static _diffRelative(t, e, s) {
    const n = !!Mt(s.round) || s.round,
      r = (t, r) => {
        t = Ft(t, n || s.calendary ? 0 : 2, !0);
        return e._loc.clone(s).relFormatter(s).format(t, r);
      },
      i = n => s.calendary ? e.hasSame(t, n) ? 0 : e.startOf(n).diff(t.startOf(n), n).get(n) : e.diff(t, n).get(n);
    if (s.unit) return r(i(s.unit), s.unit);
    for (const t of s.units) {
      const e = i(t);
      if (Math.abs(e) >= 1) return r(e, t);
    }
    return r(t > e ? -0 : 0, s.units[s.units.length - 1]);
  }
  static _unsupportedZone(t) {
    return new Ae("unsupported zone", `the zone "${t.name}" is not supported`);
  }
  get(t) {
    return this[t];
  }
  getPossibleOffsets() {
    if (!this.isValid || this.isOffsetFixed) return [this];
    const t = 864e5,
      e = 6e4,
      s = qt(this._c),
      n = this.zone.offset(s - t),
      r = this.zone.offset(s + t),
      i = this.zone.offset(s - n * e),
      o = this.zone.offset(s - r * e);
    if (i === o) return [this];
    const a = s - i * e,
      u = s - o * e,
      c = Fs(a, i),
      l = Fs(u, o);
    return c.hour === l.hour && c.minute === l.minute && c.second === l.second && c.millisecond === l.millisecond ? [this._clone({
      ts: a
    }), this._clone({
      ts: u
    })] : [this];
  }
  resolvedLocaleOptions(t = {}) {
    const {
      locale: e,
      numberingSystem: s,
      calendar: n
    } = te.create(this._loc.clone(t), t).resolvedOptions(this);
    return {
      locale: e,
      numberingSystem: s,
      outputCalendar: n
    };
  }
  toLocal() {
    return this.setZone(St.defaultZone);
  }
  toUTC(t = 0, e = {}) {
    return this.setZone(dt.instance(t), e);
  }
  setZone(t, {
    keepLocalTime: e = !1,
    keepCalendarTime: s = !1
  } = {}) {
    if ((t = _t(t, St.defaultZone)).equals(this.zone)) return this;
    if (t.isValid) {
      let n = this._ts;
      if (e || s) {
        const e = t.offset(this._ts);
        n = $s(this.toObject(), e, t)[0];
      }
      return this._clone({
        ts: n,
        zone: t
      });
    }
    return Js.invalid(Js._unsupportedZone(t));
  }
  reconfigure(t) {
    const e = this._loc.clone(t);
    return this._clone({
      loc: e
    });
  }
  setLocale(t) {
    return this.reconfigure({
      locale: t
    });
  }
  set(t) {
    if (!this.isValid) return this;
    const e = Rt(t, Rs),
      s = kt(e.weekYear) || kt(e.weekNumber) || kt(e.weekday),
      n = kt(e.ordinal),
      r = kt(e.year),
      i = kt(e.month) || kt(e.day),
      a = r || i,
      u = e.weekYear || e.weekNumber;
    if ((a || n) && u) throw new o("Can't mix weekYear/weekNumber units with year/month/day or ordinals");
    if (i && n) throw new o("Can't mix ordinal dates with month/day");
    let c;
    s ? c = Ds(Object.assign(Object.assign({}, Ns(this._c)), e)) : Mt(e.ordinal) ? (c = Object.assign(Object.assign({}, this.toObject()), e), Mt(e.day) && (c.day = Math.min(Lt(c.year, c.month), c.day))) : c = js(Object.assign(Object.assign({}, Es(this._c)), e));
    const [l, h] = $s(c, this._o, this.zone);
    return this._clone({
      ts: l,
      o: h
    });
  }
  plus(t) {
    if (!this.isValid) return this;
    const e = Ye.fromDurationLike(t);
    return this._clone(this._adjustTime(e));
  }
  minus(t) {
    if (!this.isValid) return this;
    const e = Ye.fromDurationLike(t).negate();
    return this._clone(this._adjustTime(e));
  }
  startOf(t) {
    if (!this.isValid) return this;
    const e = {},
      s = Ye.normalizeUnit(t);
    switch (s) {
      case "years":
        e.month = 1;
      case "quarters":
      case "months":
        e.day = 1;
      case "weeks":
      case "days":
        e.hour = 0;
      case "hours":
        e.minute = 0;
      case "minutes":
        e.second = 0;
      case "seconds":
        e.millisecond = 0;
    }
    if ("weeks" === s && (e.weekday = 1), "quarters" === s) {
      const t = Math.ceil(this.month / 3);
      e.month = 3 * (t - 1) + 1;
    }
    return this.set(e);
  }
  endOf(t) {
    return this.plus({
      [t]: 1
    }).startOf(t).minus({
      milliseconds: 1
    });
  }
  toFormat(t, e = {}) {
    return this.isValid ? te.create(this._loc.redefaultToEN(e)).formatDateTimeFromString(this, t) : Vs;
  }
  toLocaleString(t = w, e = {}) {
    return this.isValid ? te.create(this._loc.clone(e), t).formatDateTime(this) : Vs;
  }
  toLocaleParts(t = {}) {
    return this.isValid ? te.create(this._loc.clone(t), t).formatDateTimeParts(this) : [];
  }
  toISO({
    format: t = "extended",
    suppressSeconds: e = !1,
    suppressMilliseconds: s = !1,
    includeOffset: n = !0,
    extendedZone: r = !1
  } = {}) {
    if (!this.isValid) return null;
    const i = "extended" === t;
    return [this._toISODate(i), "T", this._toISOTime(i, e, s, n, r)].join("");
  }
  toISODate(t = {
    format: "extended"
  }) {
    let e = "basic" === t.format ? "yyyyMMdd" : "yyyy-MM-dd";
    return this.year > 9999 && (e = "+" + e), Ls(this, e);
  }
  toISOWeekDate() {
    return Ls(this, "kkkk-'W'WW-c");
  }
  toISOTime({
    suppressMilliseconds: t = !1,
    suppressSeconds: e = !1,
    includeOffset: s = !0,
    includePrefix: n = !1,
    extendedZone: r = !1,
    format: i = "extended"
  } = {}) {
    return this.isValid ? [n ? "T" : "", this._toISOTime("extended" === i, e, t, s, r)].join("") : null;
  }
  toRFC2822() {
    return Ls(this, "EEE, dd LLL yyyy HH:mm:ss ZZZ", !1);
  }
  toHTTP() {
    return Ls(this.toUTC(), "EEE, dd LLL yyyy HH:mm:ss 'GMT'");
  }
  toSQLDate() {
    return this.isValid ? this._toISODate(!0) : null;
  }
  toSQLTime({
    includeOffset: t = !0,
    includeZone: e = !1,
    includeOffsetSpace: s = !0
  } = {}) {
    let n = "HH:mm:ss.SSS";
    return (e || t) && (s && (n += " "), e ? n += "z" : t && (n += "ZZ")), Ls(this, n, !0);
  }
  toSQL(t = {}) {
    return this.isValid ? `${this.toSQLDate()} ${this.toSQLTime(t)}` : null;
  }
  toString() {
    return this.isValid ? this.toISO() : Vs;
  }
  valueOf() {
    return this.toMillis();
  }
  toMillis() {
    return this.isValid ? this.ts : NaN;
  }
  toSeconds() {
    return this.isValid ? this._ts / 1e3 : NaN;
  }
  toUnixInteger() {
    return this.isValid ? Math.floor(this.ts / 1e3) : NaN;
  }
  toJSON() {
    return this.toISO();
  }
  toBSON() {
    return this.toJSDate();
  }
  toObject(t = {
    includeConfig: !1
  }) {
    if (!this.isValid) return {};
    const e = Object.assign({}, this._c);
    return t.includeConfig && (e.outputCalendar = this.outputCalendar, e.numberingSystem = this._loc.numberingSystem, e.locale = this._loc.locale), e;
  }
  toJSDate() {
    return new Date(this.isValid ? this._ts : NaN);
  }
  diff(t, e = "milliseconds", s = {}) {
    if (!this.isValid || !t.isValid) {
      const e = this.invalidReason || t.invalidReason;
      return Ye.invalid(e, "created by diffing an invalid DateTime");
    }
    const n = (o = e, Array.isArray(o) ? o : [o]).map(Ye.normalizeUnit),
      r = t.valueOf() > this.valueOf(),
      i = ts(r ? this : t, r ? t : this, n, Object.assign({
        locale: this.locale,
        numberingSystem: this.numberingSystem
      }, s));
    var o;
    return r ? i.negate() : i;
  }
  diffNow(t = "milliseconds", e = {}) {
    return this.diff(Js.now(), t, e);
  }
  until(t) {
    return Qe.fromDateTimes(this, t);
  }
  hasSame(t, e) {
    if (!this.isValid) return !1;
    const s = t.valueOf(),
      n = this.setZone(t.zone, {
        keepLocalTime: !0
      });
    return +n.startOf(e) <= s && s <= +n.endOf(e);
  }
  equals(t) {
    return this.valueOf() === t.valueOf() && this.zone.equals(t.zone) && this._loc.equals(t._loc);
  }
  toRelative(t = {}) {
    if (!this.isValid) return null;
    const e = t.base || Js.fromObject({}, {
        zone: this.zone
      }),
      s = t.padding ? this < e ? -t.padding : t.padding : 0;
    let n = ["years", "months", "days", "hours", "minutes", "seconds"],
      r = t.unit;
    return Array.isArray(t.unit) && (n = t.unit, r = void 0), Js._diffRelative(e, this.plus(s), Object.assign(Object.assign({}, t), {
      numeric: "always",
      units: n,
      unit: r
    }));
  }
  toRelativeCalendar(t = {}) {
    return this.isValid ? Js._diffRelative(t.base || Js.fromObject({}, {
      zone: this.zone
    }), this, Object.assign(Object.assign({}, t), {
      numeric: "auto",
      units: ["years", "months", "days"],
      calendary: !0
    })) : null;
  }
  _possiblyCachedWeekData(t) {
    return null === t._weekData && (t._weekData = Ns(t._c)), t._weekData;
  }
  _clone(t) {
    const e = {
      ts: this._ts,
      zone: this.zone,
      c: this._c,
      o: this._o,
      loc: this._loc,
      invalid: this._invalid || void 0
    };
    return new Js(Object.assign(Object.assign(Object.assign({}, e), t), {
      old: e
    }));
  }
  _adjustTime(t) {
    const e = this._o,
      s = this._c.year + Math.trunc(t.years),
      n = this._c.month + Math.trunc(t.months) + 3 * Math.trunc(t.quarters),
      r = Object.assign(Object.assign({}, this._c), {
        year: s,
        month: n,
        day: Math.min(this._c.day, Lt(s, n)) + Math.trunc(t.days) + 7 * Math.trunc(t.weeks)
      }),
      i = Ye.fromObject({
        years: t.years - Math.trunc(t.years),
        quarters: t.quarters - Math.trunc(t.quarters),
        months: t.months - Math.trunc(t.months),
        weeks: t.weeks - Math.trunc(t.weeks),
        days: t.days - Math.trunc(t.days),
        hours: t.hours,
        minutes: t.minutes,
        seconds: t.seconds,
        milliseconds: t.milliseconds
      }).as("milliseconds"),
      o = qt(r);
    let [a, u] = Zs(o, e, this.zone);
    return 0 !== i && (a += i, u = this.zone.offset(a)), {
      ts: a,
      o: u
    };
  }
  _toISODate(t) {
    const e = this._c.year > 9999 || this._c.year < 0;
    let s = "";
    return e && this._c.year >= 0 && (s += "+"), s += It(this._c.year, e ? 6 : 4), t ? (s += "-", s += It(this._c.month), s += "-", s += It(this._c.day)) : (s += It(this._c.month), s += It(this._c.day)), s;
  }
  _toISOTime(t, e, s, n, r) {
    let i = It(this._c.hour);
    return t ? (i += ":", i += It(this._c.minute), 0 === this._c.millisecond && 0 === this._c.second && e || (i += ":")) : i += It(this._c.minute), 0 === this._c.millisecond && 0 === this._c.second && e || (i += It(this._c.second), 0 === this._c.millisecond && s || (i += ".", i += It(this._c.millisecond, 3))), n && (this.isOffsetFixed && 0 === this.offset && !r ? i += "Z" : this._o < 0 ? (i += "-", i += It(Math.trunc(-this._o / 60)), i += ":", i += It(Math.trunc(-this._o % 60))) : (i += "+", i += It(Math.trunc(this._o / 60)), i += ":", i += It(Math.trunc(this._o % 60)))), r && (i += "[" + this.zone.ianaName + "]"), i;
  }
}
Js.DATE_SHORT = w, Js.DATE_MED = O, Js.DATE_MED_WITH_WEEKDAY = b, Js.DATE_FULL = v, Js.DATE_HUGE = T, Js.TIME_SIMPLE = S, Js.TIME_WITH_SECONDS = k, Js.TIME_WITH_SHORT_OFFSET = M, Js.TIME_WITH_LONG_OFFSET = N, Js.TIME_24_SIMPLE = D, Js.TIME_24_WITH_SECONDS = E, Js.TIME_24_WITH_SHORT_OFFSET = j, Js.TIME_24_WITH_LONG_OFFSET = x, Js.DATETIME_SHORT = I, Js.DATETIME_SHORT_WITH_SECONDS = V, Js.DATETIME_MED = C, Js.DATETIME_MED_WITH_SECONDS = Z, Js.DATETIME_MED_WITH_WEEKDAY = F, Js.DATETIME_FULL = $, Js.DATETIME_FULL_WITH_SECONDS = z, Js.DATETIME_HUGE = L, Js.DATETIME_HUGE_WITH_SECONDS = q;
const Ys = "4.3.1";
export { Js as DateTime, Ye as Duration, dt as FixedOffsetZone, y as IANAZone, Ke as Info, Qe as Interval, h as Intl, mt as InvalidZone, Gt as ORDERED_UNITS, Bt as REVERSE_ORDERED_UNITS, St as Settings, yt as SystemZone, Ys as VERSION, l as Zone };
