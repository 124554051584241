import { Inject, Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import {
	DeviceConditionSeverity,
	DeviceState,
	InventoryDevice,
	SortedDeviceConditionCollection
} from '@shure/cloud/shared/models/devices';
import { APP_ENVIRONMENT, AppEnvironment } from '@shure/cloud/shared/utils/config';
import { ILogger } from '@shure/shared/angular/utils/logging';

@Injectable()
export class DeviceStatusService {
	public static readonly lowBatteryThreshold = 0.05; // 5%
	public static readonly veryLowBatteryThreshold = 0.02; // 2%

	private logger: ILogger;

	constructor(
		logger: ILogger,
		private translocoService: TranslocoService,
		@Inject(APP_ENVIRONMENT) private appEnv: AppEnvironment
	) {
		this.logger = logger.createScopedLogger('DeviceStatusService');
	}

	public conditionsFor(device: InventoryDevice): SortedDeviceConditionCollection {
		return this.appEnv.showProxiedDevices
			? this.getConditionsFromBatteryLevel(device)
			: new SortedDeviceConditionCollection();
	}

	private getConditionsFromBatteryLevel(device: InventoryDevice): SortedDeviceConditionCollection {
		const conditionCollection = new SortedDeviceConditionCollection();

		if (device.deviceState !== DeviceState.Online) {
			return conditionCollection;
		}
		if (!device.features.batteryLevel?.isMissing) {
			const batteryLevel = device.features.batteryLevel?.percentage ?? Number.NaN;
			if (batteryLevel <= DeviceStatusService.veryLowBatteryThreshold) {
				conditionCollection.add({
					severity: DeviceConditionSeverity.High,
					description: this.translocoService.translate(
						'cloud.device-management.inventory.mic-battery-very-low',
						{
							percent: this.formatBatteryLevel(batteryLevel)
						}
					)
				});
			} else if (batteryLevel <= DeviceStatusService.lowBatteryThreshold) {
				conditionCollection.add({
					severity: DeviceConditionSeverity.Moderate,
					description: this.translocoService.translate('cloud.device-management.inventory.mic-battery-low', {
						percent: this.formatBatteryLevel(batteryLevel)
					})
				});
			}
		}

		this.logger.trace(
			'conditionsFor',
			device.name,
			JSON.stringify({
				conditions: conditionCollection.conditions
			})
		);
		return conditionCollection;
	}

	private formatBatteryLevel(batteryLevel: number): string {
		return Number.isNaN(batteryLevel) ? '--' : `${batteryLevel}%`;
	}
}
